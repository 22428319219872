export const etsyListingsMixin = {

  methods: {
    saveDraft() {
      let confirm = () => {
        this.save(false, true, true)
        this.$store.commit('setSaveInDraftsPopup', false)
        return true
      }
      this.saveInDraftsFunc(confirm)
    },

    save(edit = false, draft = false, heartysanDeal = false) {

      if (!this.EL.firstValidation()) return

      let data = this.EL.prepareSave()
      let files = this.EL.prepareFiles()
      let saveType = 'createEtsyListings'
      let formData = new FormData();
      let deleteImageIds = this.EL.getDeleteImageIds()

      if (draft) {
        data['state'] = 'draft'
      }

      if (edit) {
        data['listing_id'] = parseInt(this.EL.getListingId())
        if (data['state'] === 'edit') {
          data['state'] = 'inactive'
        }
        saveType = 'updateEtsyListings'
      }

      formData.append("inputData", JSON.stringify(data));
      formData.append("deleteImageIds", JSON.stringify(deleteImageIds));

      /**
       * Heartysan save data
       */
      if(!edit && heartysanDeal && this.$route.query.dealId){
        formData.append("heartyDealId", this.$route.query.dealId);
        data['state'] = 'draft'
      }


      files.map((item, index) => {
        formData.append(`file_${index}`, item.item);
      })

      this.$store.dispatch(saveType, formData).then(response => {
        response.status = response?.data?.status || response?.response?.status

        switch (response.status) {
            /**
             * Success
             */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

            if (edit) {
              this.openNotify('success', 'common_notificationRecordChanged')
            } else {
              this.openNotify('success', 'common_notificationRecordCreated')
            }

            this.$router.push(this.$store.getters.GET_PATHS.mainSettingsEtsyListings + '/' + data['shop_id'])

            break
          }
            /**
             * Validation Error
             */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
            /**
             * Forbidden Error
             */
          case this.$store.getters.GET_ERRORS.FORBIDDEN_CODE: {
            let errors = response.data.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
            /**
             * Undefined Error
             */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      })
    },

    removeItem(shopId, listingId) {
      let text = {
        title: 'etsyListings_DeleteEtsyListings',
        txt: '',
        yes: 'common_confirmDelete',
        no: 'common_confirmNo'
      }

      let data = {
        "shop_id": shopId,
        "listing_id": listingId
      }

      let confirm = () => {

        this.$store.dispatch('deleteEtsyListings', data).then((response) => {
          if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            this.$emit('reload')
            this.openNotify('success', 'common_notificationRecordDeleted')
          } else {
            setTimeout(() => {
              this.openNotify('error', 'common_notificationUndefinedError')
            }, 200)
          }
        })

        this.$store.commit('setConfirmDeletePopup', false)
        return true
      }

      this.deleteFunc(text, confirm)
    },

    cloneItem(shopId, listingId) {
      let data = {
        "shop_id": shopId,
        "listing_id": listingId
      }

      return this.$store.dispatch('cloneEtsyListings', data).then(() => {
        this.$emit('reload')
      })
    },
  },
}
