<template>
  <div class="fragment">
    <div class="cus-section">
      <div class="cus-section__inner">
        <div class="cus-section__info mw-370">
          <div class="section-label large"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_photos'])"></div>
            {{ $t('etsyListing_photos.localization_value.value') }}
<!--            Photos-->
          </div>
          <div class="fragment"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_photosDesc'])"></div>
            <p>
            {{ $t('etsyListing_photosDesc.localization_value.value') }}
<!--              Add as many as you can so buyers can see every detail.-->
            </p>
          </div>

          <div class="section-label"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_photosDifficult'])"></div>
            {{ $t('etsyListing_photosDifficult.localization_value.value') }}
<!--            Photos *-->
          </div>
          <div class="fragment"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_photosDifficultDesc', 'etsyListing_photosDifficultDesc2'])"></div>
            <p class="mb-0" v-html="$t('etsyListing_photosDifficultDesc.localization_value.value')"></p>
            <p v-html="$t('etsyListing_photosDifficultDesc2.localization_value.value')"></p>
          </div>

          <div class="section-label"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_linkPhotosToVar'])"></div>
            {{ $t('etsyListing_linkPhotosToVar.localization_value.value') }}
<!--            Link photos to variations-->
          </div>

          <div class="fragment"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_linkPhotosToVarDesc'])"></div>
            <p>
              {{ $t('etsyListing_linkPhotosToVarDesc.localization_value.value') }}
<!--              Add photos to your variations so buyers can see all their options. Try it out-->
            </p>
          </div>

        </div>
        <div class="cus-section__list">
          <PhotoListings
            :EL="EL"
          />
        </div>
      </div>
    </div>

    <div class="cus-section">
      <div class="custom-row">
        <div class="custom-col custom-col--sxl-66 custom-col--md-100">
          <div class="section-label large"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_photos','etsyListing_whatIsStatus', 'etsyListing_whatIsStatusAnswer'])"></div>
            {{ $t('etsyListing_status.localization_value.value') }}
<!--            Status-->

            <v-popover
                class="site-tooltip site-tooltip--radio-after"
                :disabled="!tooltipAddProductActive"
                offset="5"
                placement="top-left"
                trigger="hover"

            >

              <TooltipBtn class="top-left"/>

              <template slot="popover">
                <p>
                  <b>
                    {{ $t('etsyListing_whatIsStatus.localization_value.value') }}
<!--                    What is Status-->
                  </b>
                </p>
                <p v-html="$t('etsyListing_whatIsStatusAnswer.localization_value.value')">
<!--                  Please, choose the status of the product:-->
<!--                  <b>Active</b> - if you want to start sale it;-->
<!--                  <b>Unactive</b> - if you want just save the information about the product, but not sale;-->
<!--                  <b>Draft</b> - if you don’t have the full information for the product and can’t start selling it-->
                </p>
              </template>
            </v-popover>
          </div>

          <div class="custom-row" v-if="EL.data.params.status">
            <div class="custom-col custom-col--16 custom-col--sxl-33 custom-col--sm-50 custom-col--es-100 mb-0"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['etsyListing_'+ EL.data.params.status['active'] +'Status'])"></div>
              <LargeRadio
                  class="soft-text mw-100"
                  :label="$t('etsyListing_'+ EL.data.params.status['active'] +'Status.localization_value.value')"
                  :name="'params-status'"
                  @change="(val) => (EL.setStatus(val))"
                  :checked="EL.data.params.status['active'] === EL.data.status"
                  :value="EL.data.params.status['active']"
              />
            </div>
            <div class="custom-col custom-col--16 custom-col--sxl-33 custom-col--sm-50 custom-col--es-100 mb-0"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}"
                v-if="editingSign"
            >
              <div class="admin-edit" @click="editTranslate(['etsyListing_'+ EL.data.params.status['inactive'] +'Status'])"></div>
              <LargeRadio
                  class="soft-text mw-100"
                  :label="$t('etsyListing_'+ EL.data.params.status['inactive'] +'Status.localization_value.value')"
                  :name="'params-status'"
                  @change="(val) => (EL.setStatus(val))"
                  :checked="EL.data.params.status['inactive'] === EL.data.status"
                  :value="EL.data.params.status['inactive']"
              />
            </div>
<!--            <div class="custom-col custom-col&#45;&#45;16 custom-col&#45;&#45;sxl-33 custom-col&#45;&#45;sm-50 custom-col&#45;&#45;es-100 mb-0"-->
<!--                 v-for="(item, index) in EL.data.params.status"-->
<!--                 :key="index"-->
<!--                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--              <div class="admin-edit" @click="editTranslate(['etsyListing_'+ item +'Status'])"></div>-->
<!--              <LargeRadio-->
<!--                  class="soft-text"-->
<!--                  :label="$t('etsyListing_'+ item +'Status.localization_value.value')"-->
<!--                  :name="'params-status'"-->
<!--                  @change="(val) => (EL.setStatus(val))"-->
<!--                  :checked="item === EL.data.status"-->
<!--                  :value="item"-->
<!--              />-->
<!--            </div>-->
          </div>
        </div>
      </div>
    </div>

    <div class="cus-section">
      <div class="custom-row">
        <div class="custom-col custom-col--50 custom-col--sxl-66 custom-col--md-100">
          <div class="section-label large"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_listingDetails'])"></div>
            {{ $t('etsyListing_listingDetails.localization_value.value') }}
            <!--            Listing Details-->
          </div>

          <div class="fragment"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_title'])"></div>
            <DefaultInput
                :label="$t('etsyListing_title.localization_value.value')"
                v-bind:class="{'ui-no-valid': EL.validation.title}"
                :errorTxt="$t(`${EL.validationTranslate.title}.localization_value.value`)"
                :error="EL.validation.title"
                v-model="EL.data.title"
            />
          </div>
        </div>

        <div class="custom-col custom-col--50 custom-col--sxl-66 custom-col--md-100">
          <div class="section-label large"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_type', 'etsyListing_typeWhatIs', 'etsyListing_typeWhatIs'])"></div>
            {{ $t('etsyListing_type.localization_value.value') }}
            <!--Type-->


            <v-popover
                class="site-tooltip site-tooltip--radio-after"
                :disabled="!tooltipAddProductActive"
                offset="5"
                placement="top-left"
                trigger="hover"

            >

              <TooltipBtn class="top-left"/>

              <template slot="popover">
                <p>
                  <b>{{ $t('etsyListing_typeWhatIs.localization_value.value') }}</b>
<!--                  <b>What is Type</b>-->
                </p>
                <p v-html="$t('etsyListing_typeWhatIsDesc.localization_value.value')">
                  <b>Physical</b> A tangible item that you will ship to buyers. <br>
                  <b>Digital</b> A digital file that buyers will download.
                </p>
              </template>
            </v-popover>
          </div>


          <div class="custom-row mb-0" v-if="EL.data.params['is_digital']">
            <div class="custom-col custom-col--33 custom-col--sm-50 custom-col--es-100 mb-0"
                 v-for="(item, index) in EL.data.params['is_digital']"
                 :key="index"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['etsyListing_'+ item['key'] +'Status'])"></div>

              <LargeRadio
                  class="soft-text mw-100"
                  :label="$t('etsyListing_'+ item['key'] +'Type.localization_value.value')"
                  :name="'params-type'"
                  @change="(val) => (EL.setIsDigital(val))"
                  :checked="item['value'] === EL.data.isDigital"
                  :value="item['value']"
              />
            </div>


<!--            <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;sm-50 custom-col&#45;&#45;es-100 mb-0"-->
<!--                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--              <div class="admin-edit" @click="editTranslate(['etsyListing_physicalIsDigital'])"></div>-->
<!--              <LargeRadio-->
<!--                  class="soft-text"-->
<!--                  :label="$t('etsyListing_physicalIsDigital.localization_value.value')"-->
<!--                  :name="'params-type'"-->
<!--                  @change="(val) => (EL.setIsDigital('physical'))"-->
<!--                  :checked="EL.data.params['when_made']['is_digital']['physical'].name === EL.data.isDigital"-->
<!--                  :value="EL.data.params['when_made']['is_digital']['physical']"-->
<!--              />-->
<!--            </div>-->
<!--            <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;sm-50 custom-col&#45;&#45;es-100 mb-0"-->
<!--                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--              <div class="admin-edit" @click="editTranslate(['etsyListing_digitalIsDigital'])"></div>-->
<!--              <LargeRadio-->
<!--                  class="soft-text"-->
<!--                  :label="$t('etsyListing_digitalIsDigital.localization_value.value')"-->
<!--                  :name="'params-type'"-->
<!--                  @change="(val) => (EL.setIsDigital('digital'))"-->
<!--                  :checked="EL.data.params['when_made']['is_digital']['digital'].name === EL.data.isDigital"-->
<!--                  :value="EL.data.params['when_made']['is_digital']['digital']"-->
<!--              />-->
<!--            </div>-->
          </div>
        </div>

        <div class="custom-col custom-col--50 custom-col--sxl-66 custom-col--md-100">
          <div class="section-label large"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_whoMadeIt'])"></div>
            {{ $t('etsyListing_whoMadeIt.localization_value.value') }}
            <!--Who Made It-->
          </div>

          <div class="custom-row mb-0">
            <!--            :label="'I did'"-->
            <!--            :label="'Another Shop'"-->
            <!--            :label="'Other company'"-->
            <div class="custom-col custom-col--33 custom-col--sm-50 custom-col--es-100 mb-0"
                 v-for="(item, index) in EL.data.params['who_made']"
                 :key="index"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['etsyListing_'+ item['key']])"></div>
              <LargeRadio
                  class="soft-text mw-100"
                  :class="{'font-size-small-16' : item['value'] === 'someone_else'}"
                  :label="$t('etsyListing_'+ item['key'] +'.localization_value.value')"
                  :name="'params-who-made'"
                  @change="(val) => (EL.setWhoMade(val))"
                  :checked="item['value'] === EL.data.whoMade"
                  :value="item['value']"
              />
            </div>
            <!--            <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;sm-50 custom-col&#45;&#45;es-100 mb-0"-->
            <!--                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
            <!--              <div class="admin-edit" @click="editTranslate(['etsyListing_etsyListing_iDidType'])"></div>-->
            <!--              <LargeRadio-->
            <!--                  class="soft-text"-->
            <!--                  :label="$t('etsyListing_iDidType.localization_value.value')"-->
            <!--                  :name="'params-who-made'"-->
            <!--                  @change="(val) => (EL.setWhoMade('i_did'))"-->
            <!--                  :checked="EL.data.params['who_made']['i_did'] === EL.data.whoMade"-->
            <!--                  :value="EL.data.params['who_made']['i_did']"-->
            <!--              />-->
            <!--            </div>-->

<!--            <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;sm-50 custom-col&#45;&#45;es-100 mb-0"-->
<!--                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--              <div class="admin-edit" @click="editTranslate(['etsyListing_etsyListing_iDidType'])"></div>-->
<!--              <LargeRadio-->
<!--                  class="soft-text"-->
<!--                  :label="$t('etsyListing_iDidType.localization_value.value')"-->
<!--                  :name="'params-who-made'"-->
<!--                  @change="(val) => (EL.setWhoMade('i_did'))"-->
<!--                  :checked="EL.data.params['who_made']['i_did'] === EL.data.whoMade"-->
<!--                  :value="EL.data.params['who_made']['i_did']"-->
<!--              />-->
<!--            </div>-->
<!--            <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;sm-50 custom-col&#45;&#45;es-100 mb-0"-->
<!--                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--              <div class="admin-edit" @click="editTranslate(['etsyListing_etsyListing_collectiveType'])"></div>-->
<!--              <LargeRadio-->
<!--                  class="soft-text"-->
<!--                  :label="$t('etsyListing_collectiveType.localization_value.value')"-->
<!--                  :name="'params-who-made'"-->
<!--                  @change="(val) => (EL.setWhoMade('collective'))"-->
<!--                  :checked="EL.data.params['who_made']['collective'] === EL.data.whoMade"-->
<!--                  :value="EL.data.params['who_made']['collective']"-->
<!--              />-->
<!--            </div>-->
<!--            <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;sm-50 custom-col&#45;&#45;es-100 mb-0"-->
<!--                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--              <div class="admin-edit" @click="editTranslate(['etsyListing_someoneElseType'])"></div>-->
<!--              <LargeRadio-->
<!--                  class="soft-text"-->
<!--                  :label="$t('etsyListing_someoneElseType.localization_value.value')"-->
<!--                  :name="'params-who-made'"-->
<!--                  @change="(val) => (EL.setWhoMade('someone_else'))"-->
<!--                  :checked="EL.data.params['who_made']['someone_else'] === EL.data.whoMade"-->
<!--                  :value="EL.data.params['who_made']['someone_else']"-->
<!--              />-->
<!--            </div>-->
          </div>

        </div>

        <div class="custom-col custom-col--33 custom-col--xl-50 custom-col--md-100 custom-col--xs-100">
          <div class="section-label large"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_whatIsIt'])"></div>
            {{ $t('etsyListing_whatIsIt.localization_value.value') }}
            <!--What is It-->
          </div>
          <div class="custom-row mb-0">
            <div class="custom-col custom-col--50 custom-col--es-100 mb-0"
                 v-for="(item, index) in EL.data.params['is_supply']"
                 :key="index"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['etsyListing_'+ item['key']])"></div>
              <LargeRadio
                  class="soft-text mw-100"
                  :class="{'font-size-small-14' : item['value']}"
                  :label="$t('etsyListing_'+ item['key'] +'.localization_value.value')"
                  :name="'params-what-is-it'"
                  @change="(val) => (EL.setIsSupply(val))"
                  :checked="item['value'] === EL.data.isSupply"
                  :value="item['value']"
              />
            </div>


<!--            <div class="custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;es-100 mb-0"-->
<!--                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--              <div class="admin-edit" @click="editTranslate(['etsyListing_finishedProductIsSupply'])"></div>-->
<!--              <LargeRadio-->
<!--                  class="soft-text"-->
<!--                  :label="$t('etsyListing_finishedProductIsSupply.localization_value.value')"-->
<!--                  :name="'params-what-is-it'"-->
<!--                  @change="(val) => (EL.setIsSupply(val))"-->
<!--                  :checked="EL.data.params['is_supply'][0]['value'] === EL.data.isSupply"-->
<!--                  :value="EL.data.params['is_supply'][0]['value']"-->
<!--              />-->
<!--            </div>-->

<!--            <div class="custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;es-100 mb-0"-->
<!--                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--              <div class="admin-edit" @click="editTranslate(['etsyListing_supplyOrToolIsSupply'])"></div>-->
<!--              <LargeRadio-->
<!--                  class="soft-text"-->
<!--                  :label="$t('etsyListing_supplyOrToolIsSupply.localization_value.value')"-->
<!--                  :name="'params-what-is-it'"-->
<!--                  @change="(val) => (EL.setIsSupply(val))"-->
<!--                  :checked="EL.data.params['is_supply'][1]['value'] === EL.data.isSupply"-->
<!--                  :value="EL.data.params['is_supply'][1]['value']"-->
<!--              />-->
<!--            </div>-->


          </div>
        </div>

        <div class="custom-col custom-col--16 custom-col--xl-33 custom-col--sxl-33 custom-col--md-100">
          <div class="section-label large"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_whenItWasMade'])"></div>
            {{ $t('etsyListing_whenItWasMade.localization_value.value') }}
            <!--When it was made-->
          </div>

          <div class="fragment"
               v-if="EL.data.params['when_made']"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_creation'])"></div>
            <!--            Creation-->
            <DefaultSelect
                :label="$t('etsyListing_whenMadeCreation.localization_value.value')"
                :options="Object.values(EL.data.params['when_made'])"
                :otherValue="'groups'"
                v-bind:class="{'ui-no-valid': EL.validation.whenMade}"
                :errorTxt="$t(`${EL.validationTranslate.whenMade}.localization_value.value`)"
                :error="EL.validation.whenMade"
                :selected="EL.data.whenMade"
                @change="(val) => EL.setWhenMade(val)"
            />
          </div>


        </div>

        <div class="custom-col custom-col--50 custom-col--md-100">
          <div class="section-label large"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_category'])"></div>
            {{ $t('etsyListing_category.localization_value.value') }}
<!--            Category-->
          </div>

          <div class="fragment"
               v-if="EL.data.params['when_made']"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_categoryLabel'])"></div>
<!--            Start typing to choose category-->
            <DefaultInput
                :type="'text'"
                :label="$t('etsyListing_categoryLabel.localization_value.value')"
                :readonly="true"
                v-bind:class="{'ui-no-valid': EL.validation.categoryObjToString}"
                :errorTxt="$t(`${EL.validationTranslate.categoryObjToString}.localization_value.value`)"
                :error="EL.validation.categoryObjToString"
                v-model="EL.data.categoryObjToString"
                @click.native="changeCategoryPopup(true)"
            />
          </div>


        </div>

        <div class="custom-col custom-col--50 custom-col--md-100">
          <div class="section-label large"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_renewalOptions', 'etsyListing_renewalOptionsDesc'])"></div>
            {{ $t('etsyListing_renewalOptions.localization_value.value') }}
<!--            Renewal Options-->

            <v-popover
                class="site-tooltip site-tooltip--radio-after"
                :disabled="!tooltipAddProductActive"
                offset="5"
                placement="top-left"
                trigger="hover"
            >
              <TooltipBtn class="top-left"/>

              <template slot="popover">
                <p>
                  <b>{{ $t('etsyListing_renewalOptions.localization_value.value') }}</b>
                </p>
                <p class="mb-0" v-html="$t('etsyListing_renewalOptionsDesc.localization_value.value')"></p>
                <p v-html="$t('etsyListing_renewalOptionsDesc2.localization_value.value')">
<!--                  Each renewal lasts for four months or until the listing sales out.-->
<!--                  <b>Authomatic</b>will renew as it expires for $0,20 USD each time (recommended option),-->
<!--                  <b>manual</b> is listing which is needed to renew by yourself, but it’s free-->
                </p>
              </template>
            </v-popover>
          </div>


          <div class="custom-row mb-0">
            <div class="custom-col custom-col--33 custom-col--sm-50 custom-col--es-100 mb-0"
                 v-for="(item, index) in EL.data.params['should_auto_renew']"
                 :key="index"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['etsyListing_'+ item['key']])"></div>
              <LargeRadio
                  class="soft-text mw-100"
                  :label="$t('etsyListing_'+ item['key'] +'.localization_value.value')"
                  :name="'params-renewal-options'"
                  @change="(val) => (EL.setShouldAutoRenew(val))"
                  :checked="item['value'] === EL.data.shouldAutoRenew"
                  :value="item['value']"
              />
            </div>
<!--            <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;sm-50 custom-col&#45;&#45;es-100 mb-0">-->
<!--              <LargeRadio-->
<!--                  class="soft-text"-->
<!--                  :label="'Authomatic'"-->
<!--                  :name="'type3'"-->
<!--              />-->
<!--            </div>-->
<!--            <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;sm-50 custom-col&#45;&#45;es-100 mb-0">-->
<!--              <LargeRadio-->
<!--                  class="soft-text"-->
<!--                  :label="'Manual'"-->
<!--                  :name="'type3'"-->
<!--              />-->
<!--            </div>-->

          </div>
        </div>

        <div v-if="EL.data.isDigital === 'false' || EL.data.isDigital === false" class="custom-col custom-col--50 custom-col--md-100">
          <div class="section-label large"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_shippingTemplate', 'etsyListing_shippingTemplateDesc'])"></div>
            {{ $t('etsyListing_shippingTemplate.localization_value.value') }}

            <v-popover
                class="site-tooltip site-tooltip--radio-after"
                :disabled="!tooltipAddProductActive"
                offset="5"
                placement="top-left"
                trigger="hover"

            >

              <TooltipBtn class="top-left"/>

              <template slot="popover">
                <p>
                  <b>{{ $t('etsyListing_shippingTemplate.localization_value.value') }}</b>
                </p>
                <p v-html="$t('etsyListing_shippingTemplateDesc.localization_value.value')">
                  <!--                  Shipping template (ceramics)-->
                </p>
              </template>
            </v-popover>
          </div>


          <div class="custom-row mb-0">
            <div class="custom-col custom-col--50 custom-col--md-100 mb-0">
              <DefaultSelect
                  :label="$t('etsyListing_shippingTemplate.localization_value.value')"
                  :options="Object.values(EL.data.shopInfo['shippingTemplates'])"
                  :optionsLabel="'title'"
                  :clearable="false"
                  v-bind:class="{'ui-no-valid': EL.validation.shippingProfileId}"
                  :errorTxt="$t(`${EL.validationTranslate.shippingProfileId}.localization_value.value`)"
                  :error="EL.validation.shippingProfileId"
                  :selected="EL.data.shippingProfileSelected"
                  @change="(val) => EL.setShippingProfileSelected(val)"
              />
            </div>

          </div>
        </div>

      </div>
    </div>

    <div class="cus-section" v-if="EL.data.categoryObjToString.length > 0">
      <div class="custom-row">
        <div class="custom-col custom-col--50 custom-col--md-100">
          <Taxonomy
              :EL="EL"
          />
        </div>
      </div>
    </div>

    <div class="cus-section">
      <div class="custom-row">
        <div class="custom-col custom-col--50 custom-col--md-100">
          <div class="section-label large"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_description', 'etsyListing_description2', 'etsyListing_description3'])"></div>
            {{ $t('etsyListing_description.localization_value.value') }}
<!--            Description-->
          </div>
          {{ $t('etsyListing_description2.localization_value.value') }}
<!--          Start with a brief overview that describes your item’s finest features. Shoppers will only see the first few-->
<!--          lines of your description at first, so make it count!-->
          <br>
          <br>
          {{ $t('etsyListing_description3.localization_value.value') }}
<!--          Not sure what else to say? Shoppers also like hearing about your process, and the story behind this item.-->

        </div>

        <div class="custom-col custom-col--50 custom-col--md-100">
          <TextareaDefault
              v-bind:class="{'ui-no-valid': EL.validation.description}"
              :error="EL.validation.description"
              :errorTxt="$t(`${EL.validationTranslate.description}.localization_value.value`)"
              :value="EL.data.description"
              @input="(val) => EL.setDescription(val)"
          />
        </div>

        <div class="custom-col custom-col--50 custom-col--sxl-66 custom-col--md-100"
             v-if="_.has(EL.data.shopInfo, 'shippingTemplates') &&
               _.has(EL.data.shopInfo['shippingTemplates'], 'results') &&
               EL.data.shopInfo['shippingTemplates']['results'].length > 0"
        >
          <div class="section-label large"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_shippingTemplate', 'etsyListing_shippingTemplateDesc'])"></div>
            {{ $t('etsyListing_shippingTemplate.localization_value.value') }}
<!--            Shipping template (ceramics)-->

            <v-popover
                class="site-tooltip site-tooltip--radio-after"
                :disabled="!tooltipAddProductActive"
                offset="5"
                placement="top-left"
                trigger="hover"

            >

              <TooltipBtn class="top-left"/>

              <template slot="popover">
                <p>
                  <b>{{ $t('etsyListing_shippingTemplate.localization_value.value') }}</b>
                </p>
                <p v-html="$t('etsyListing_shippingTemplateDesc.localization_value.value')">
<!--                  Shipping template (ceramics)-->
                </p>
              </template>
            </v-popover>
          </div>
          <div class="custom-row" >
            <div class="custom-col">
              <div class="fragment"
                   v-if="EL.data.params['when_made']"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['etsyListing_shippingTemplate'])"></div>
                <!--            Creation-->
                <DefaultSelect
                    :label="$t('etsyListing_shippingTemplate.localization_value.value')"
                    :options="Object.values(EL.data.shopInfo['shippingTemplates']['results'])"
                    :optionsLabel="'title'"
                    v-bind:class="{'ui-no-valid': EL.validation.shippingTemplateId}"
                    :errorTxt="$t(`${EL.validationTranslate.shippingTemplateId}.localization_value.value`)"
                    :error="EL.validation.shippingTemplateId"
                    :selected="EL.data.shippingTemplateSelected"
                    @change="(val) => EL.setShippingTemplateSelected(val)"
                />
              </div>
            </div>
          </div>

<!--          <div class="custom-row mb-0">-->
<!--            <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;sm-50 custom-col&#45;&#45;es-100 mb-0"-->
<!--                 v-for="(item, index) in EL.data.shopInfo['shippingTemplates']['results']"-->
<!--                 :key="index">-->
<!--              {{item['shipping_template_id']}} || {{item['shipping_template_id'] === EL.data.shippingTemplateId}}-->
<!--              <LargeRadio-->
<!--                  class="soft-text"-->
<!--                  :label="item['title']"-->
<!--                  :name="'shipping-templates'"-->
<!--                  @change="(val) => (EL.setShippingTemplateId(val))"-->
<!--                  :checked="item['shipping_template_id'] === EL.data.shippingTemplateId"-->
<!--                  :value="item['shipping_template_id']"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->

        </div>

        <div class="custom-col custom-col--50 custom-col--sxl-66 custom-col--md-100">
          <div class="section-label large"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_selectShop'])"></div>
            {{ $t('etsyListing_selectShop.localization_value.value') }}
<!--            Select Shop-->
          </div>

          <div class="custom-row mb-0"
               v-if="_.has(EL.data.shopInfo, 'shopSections') &&
               _.has(EL.data.shopInfo['shopSections'], 'results') &&
               EL.data.shopInfo['shopSections']['results'].length > 0">

            <div class="custom-col custom-col--33 custom-col--sm-50 custom-col--es-100 mb-0"
                 v-for="(item, index) in EL.data.shopInfo['shopSections']['results']"
                 :key="index">
              <LargeRadio
                  class="soft-text mw-100"
                  :label="item['title']"
                  :htmlFix="true"
                  :name="'select-shop-radio'"
                  @change="(val) => (EL.setShopSectionId(val))"
                  :checked="item['shop_section_id'] === EL.data.shopSectionId"
                  :value="item['shop_section_id']"
              />
            </div>
<!--            <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;sm-50 custom-col&#45;&#45;es-100 mb-0">-->
<!--              <LargeRadio-->
<!--                  class="soft-text"-->
<!--                  :label="'Porcelain USSR'"-->
<!--                  :name="'type6'"-->
<!--              />-->
<!--            </div>-->
<!--            <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;sm-50 custom-col&#45;&#45;es-100 mb-0">-->
<!--              <LargeRadio-->
<!--                  class="soft-text"-->
<!--                  :label="'VIKING ARTIFACTS'"-->
<!--                  :name="'type6'"-->
<!--              />-->
<!--            </div>-->

          </div>
        </div>

        <div class="custom-col custom-col--50 custom-col--sxl-66 custom-col--md-100">
          <div class="section-label large"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_tags'])"></div>
            {{ $t('etsyListing_tags.localization_value.value') }}
<!--            Tags — up to 12 (optional)-->
          </div>

          <div class="fragment"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_tagsLabel', 'etsyListing_tagsCaption'])"></div>
  <!--              :label="'Type tag and press enter to add'"-->
  <!--              :caption="'Press enter to add'"-->
            <DefaultInput
                :label="$t('etsyListing_tagsLabel.localization_value.value')"
                :caption="$t('etsyListing_tagsCaption.localization_value.value')"
                :maxLength="45"
                type="text"
                @onEnter="enterTags"
                v-model="tags"
            />

            <div class="d-flex mt-2">
              <div class="tag tag__list">
                <div class="tag__item wfc"
                     v-for="(item, index) in EL.data.tags"
                     :key="index"
                >
                  <div class="tag__remove btn-style"
                    @click="() => EL.removeArrayItem(index,  EL.data.arrayPointer.tags)"
                  ></div>
                  <div class="tag__text">
                    {{item}}
                  </div>
                </div>
              </div>


  <!--            <div class="soft-text font-weight-normal mt-3 ml-auto wfc d-flex site-link"-->
  <!--              @click="enterTags"-->
  <!--            >Press enter to add</div>-->
            </div>

          </div>
        </div>

        <div class="custom-col custom-col--50 custom-col--sxl-66 custom-col--md-100">
          <div class="section-label large"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_material'])"></div>
            {{ $t('etsyListing_material.localization_value.value') }}
<!--            Material — up to 13 (optional)-->
          </div>

          <div class="fragment"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_materialLabel', 'etsyListing_tagsCaption'])"></div>
<!--                :label="'Type material and press enter to add'"-->
<!--                :caption="'Press enter to add'"-->
            <DefaultInput
                :label="$t('etsyListing_materialLabel.localization_value.value')"
                :caption="$t('etsyListing_tagsCaption.localization_value.value')"
                v-model="materials"
                @onEnter="enterMaterials"
            />
            <div class="d-flex  mt-2">
              <div class="tag tag__list">
                <div class="tag__item wfc"
                     v-for="(item, index) in EL.data.materials"
                     :key="index"
                >
                  <div class="tag__remove btn-style"
                       @click="() => EL.removeArrayItem(index,  EL.data.arrayPointer.materials)"
                  ></div>
                  <div class="tag__text">
                    {{item}}
                  </div>
                </div>
              </div>
              <!--            <div class="soft-text font-weight-normal mt-3 ml-auto wfc d-flex site-link"-->
              <!--              @click="enterMaterials"-->
              <!--            >Press enter to add</div>-->
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="cus-section">
      <div class="custom-row">
        <div class="custom-col custom-col--50 custom-col--sxl-66 custom-col--md-100">
          <div class="section-label large"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_weightUnit'])"></div>
            {{ $t('etsyListing_weightUnit.localization_value.value') }}
<!--            Weight Unit-->
          </div>

          <div class="custom-row mb-0">
            <div class="custom-col custom-col--25 custom-col--sm-50 custom-col--es-100 mb-0"
                 v-for="(item, index) in EL.data.params['item_weight_unit']"
                 :key="index"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['etsyListing_'+ item])"></div>
              <LargeRadio
                  class="soft-text mw-100"
                  :label="$t('etsyListing_' + item + '.localization_value.value')"
                  :htmlFix="true"
                  :name="'weight-unit'"
                  @change="(val) => (EL.setItemWeightUnit(val))"
                  :checked="item === EL.data.itemWeightUnit"
                  :value="item"
              />
            </div>
<!--            <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;sm-50 custom-col&#45;&#45;es-100 mb-0">-->
<!--              <LargeRadio-->
<!--                  class="soft-text"-->
<!--                  :label="'OZ'"-->
<!--                  :name="'type6'"-->
<!--              />-->
<!--            </div>-->
<!--            <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;sm-50 custom-col&#45;&#45;es-100 mb-0">-->
<!--              <LargeRadio-->
<!--                  class="soft-text"-->
<!--                  :label="'KG'"-->
<!--                  :name="'type6'"-->
<!--              />-->
<!--            </div>-->
<!--            <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;sm-50 custom-col&#45;&#45;es-100 mb-0">-->
<!--              <LargeRadio-->
<!--                  class="soft-text"-->
<!--                  :label="'other'"-->
<!--                  :name="'type6'"-->
<!--              />-->
<!--            </div>-->
          </div>

        </div>

        <div class="custom-col custom-col--50 custom-col--sxl-66 custom-col--md-100">
          <div class="section-label large"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_dimensionsUnit'])"></div>
            {{ $t('etsyListing_dimensionsUnit.localization_value.value') }}
<!--            Dimensions Unit-->
          </div>

          <div class="custom-row mb-0">
            <div class="custom-col custom-col--20 custom-col--sm-50 custom-col--md-25 custom-col--es-100 mb-0"
                 v-for="(item, index) in EL.data.params['item_dimensions_unit']"
                 :key="index"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['etsyListing_'+ item])"></div>
              <LargeRadio
                  class="soft-text mw-100"
                  :label="$t('etsyListing_' + item + '.localization_value.value')"
                  :htmlFix="true"
                  :name="'dimensions-unit'"
                  @change="(val) => (EL.setItemDimensionsUnit(val))"
                  :checked="item === EL.data.itemDimensionsUnit"
                  :value="item"
              />
            </div>
<!--            <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;sm-50 custom-col&#45;&#45;es-100 mb-0">-->
<!--              <LargeRadio-->
<!--                  class="soft-text"-->
<!--                  :label="'In'"-->
<!--                  :name="'type7'"-->
<!--              />-->
<!--            </div>-->
<!--            <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;sm-50 custom-col&#45;&#45;es-100 mb-0">-->
<!--              <LargeRadio-->
<!--                  class="soft-text"-->
<!--                  :label="'CM'"-->
<!--                  :name="'type7'"-->
<!--              />-->
<!--            </div>-->
<!--            <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;sm-50 custom-col&#45;&#45;es-100 mb-0">-->
<!--              <LargeRadio-->
<!--                  class="soft-text"-->
<!--                  :label="'M'"-->
<!--                  :name="'type7'"-->
<!--              />-->
<!--            </div>-->
          </div>

        </div>

        <div class="custom-col custom-col--50 custom-col--sxl-66 custom-col--md-100 mb-0">
          <div class="section-label large"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_dimensions'])"></div>
            {{ $t('etsyListing_dimensions.localization_value.value') }}
<!--            Dimensions-->
          </div>

          <div class="custom-row mb-0">
            <div class="custom-col custom-col--25 custom-col--sm-100 custom-col--md-50"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['etsyListing_weight'])"></div>
              <DefaultInput
                  :label="$t('etsyListing_weight.localization_value.value')"
                  :placeholder="'e.g. 10'"
                  v-bind:class="{'ui-no-valid': EL.validation.itemWeight}"
                  :errorTxt="$t(`${EL.validationTranslate.itemWeight}.localization_value.value`)"
                  :error="EL.validation.itemWeight"
                  v-model="EL.data.itemWeight"
              />
            </div>
            <div class="custom-col custom-col--25 custom-col--sm-100 custom-col--md-50"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['etsyListing_height'])"></div>
              <DefaultInput
                  :label="$t('etsyListing_height.localization_value.value')"
                  :placeholder="'e.g. 10'"
                  v-bind:class="{'ui-no-valid': EL.validation.itemHeight}"
                  :errorTxt="$t(`${EL.validationTranslate.itemHeight}.localization_value.value`)"
                  :error="EL.validation.itemHeight"
                  v-model="EL.data.itemHeight"
              />
            </div>
            <div class="custom-col custom-col--25 custom-col--sm-100 custom-col--md-50"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['etsyListing_width'])"></div>
              <DefaultInput
                  :label="$t('etsyListing_width.localization_value.value')"
                  :placeholder="'e.g. 10'"
                  v-bind:class="{'ui-no-valid': EL.validation.itemWidth}"
                  :errorTxt="$t(`${EL.validationTranslate.itemWidth}.localization_value.value`)"
                  :error="EL.validation.itemWidth"
                  v-model="EL.data.itemWidth"
              />
            </div>
            <div class="custom-col custom-col--25 custom-col--sm-100 custom-col--md-50"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['etsyListing_length'])"></div>
              <DefaultInput
                  :label="$t('etsyListing_length.localization_value.value')"
                  :placeholder="'e.g. 10'"
                  v-bind:class="{'ui-no-valid': EL.validation.itemLength}"
                  :errorTxt="$t(`${EL.validationTranslate.itemLength}.localization_value.value`)"
                  :error="EL.validation.itemLength"
                  v-model="EL.data.itemLength"
              />
            </div>
          </div>

        </div>

        <div class="custom-col custom-col--12-5 custom-col--sxl-33 custom-col--md-50">
          <div class="section-label large"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_price', 'etsyListing_priceDesc'])"></div>
            {{ $t('etsyListing_price.localization_value.value') }}
<!--            Price-->

            <v-popover
                class="site-tooltip site-tooltip--radio-after"
                :disabled="!tooltipAddProductActive"
                offset="5"
                placement="top"
                trigger="hover"

            >

              <TooltipBtn/>

              <template slot="popover">
                <p>
                  <b> {{ $t('etsyListing_price.localization_value.value') }}</b>
                </p>
                <p v-html="$t('etsyListing_priceDesc.localization_value.value')">
<!--                  Remember to factor in the costs of materials, labor, and other business expenses. If you offer free-->
<!--                  shipping, make sure to include the cost of shipping so it doesn't eat into your profits.-->
                </p>
              </template>
            </v-popover>
          </div>

          <InputSum
              class="mw-138"
              :icoType="'dollar'"
              :placeholder="'0'"
              v-bind:class="{'ui-no-valid': EL.validation.price}"
              :errorTxt="$t(`${EL.validationTranslate.price}.localization_value.value`)"
              :error="EL.validation.price"
              v-model="EL.data.price"
          />
        </div>

        <div class="custom-col custom-col--12-5 custom-col--sxl-33 custom-col--md-50">
          <div class="section-label large"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_qTy', 'etsyListing_qTyDesc'])"></div>
            {{ $t('etsyListing_qTy.localization_value.value') }}
<!--            Q-ty-->

            <v-popover
                class="site-tooltip site-tooltip--radio-after"
                :disabled="!tooltipAddProductActive"
                offset="5"
                placement="top"
                trigger="hover"

            >

              <TooltipBtn/>

              <template slot="popover">
                <p>
                  <b> {{ $t('etsyListing_qTy.localization_value.value') }}</b>
                </p>
                <p v-html="$t('etsyListing_qTyDesc.localization_value.value')">
<!--                  For quantities greater than one, this listing will renew automatically until it sells out. You’ll be-->
<!--                  charged a $0.20 USD listing fee each time.-->
                </p>
              </template>
            </v-popover>
          </div>
          <InputQuantity
              v-model="EL.data.quantity"
              :label="$t('common_ItemsQty.localization_value.value')"
              v-bind:class="{'ui-no-valid': EL.validation.quantity}"
              :error="EL.validation.quantity"
              :errorTxt="$t(`${EL.validationTranslate.quantity}.localization_value.value`)"
              @change="(val) => EL.setQuantity(val)"
          />

        </div>

<!--        <div class="custom-col custom-col&#45;&#45;25 custom-col&#45;&#45;sxl-33 custom-col&#45;&#45;md-50 custom-col&#45;&#45;sm-100">-->
<!--          <div class="section-label large"-->
<!--               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--            <div class="admin-edit" @click="editTranslate(['etsyListing_SKU', 'etsyListing_SKUDesc', 'etsyListing_SKULink'])"></div>-->
<!--            {{ $t('etsyListing_SKU.localization_value.value') }}-->
<!--&lt;!&ndash;            SKU&ndash;&gt;-->

<!--            <v-popover-->
<!--                class="site-tooltip site-tooltip&#45;&#45;radio-after"-->
<!--                :disabled="!tooltipAddProductActive"-->
<!--                offset="5"-->
<!--                placement="top"-->
<!--                trigger="hover"-->

<!--            >-->

<!--              <TooltipBtn/>-->

<!--              <template slot="popover">-->
<!--                <p>-->
<!--                  <b>{{ $t('etsyListing_SKU.localization_value.value') }}</b>-->
<!--                </p>-->
<!--                <p>-->

<!--                  {{ $t('etsyListing_SKUDesc.localization_value.value') }} <br>-->
<!--                  <a target="_blank" rel="nofollow" href="https://help.etsy.com/hc/en-us/articles/115015691707?segment=selling">{{$t('etsyListing_SKULink.localization_value.value') }}</a>-->
<!--                </p>-->
<!--              </template>-->
<!--            </v-popover>-->
<!--          </div>-->

<!--          <DefaultInput-->
<!--              :label="$t('etsyListing_SKU.localization_value.value')"-->
<!--              :placeholder="'e.g. 10'"-->
<!--              v-bind:class="{'ui-no-valid': EL.validation.sku}"-->
<!--              :errorTxt="$t(`${EL.validationTranslate.sku}.localization_value.value`)"-->
<!--              :error="EL.validation.sku"-->
<!--              v-model="EL.data.sku"-->
<!--          />-->

<!--        </div>-->
      </div>
    </div>



    <CategoryPopup
        :EL="EL"
        v-if="isModalCategoryPopup"
        @close="changeCategoryPopup(false)"
    />

  </div>
</template>

<script>
import LargeRadio from "../../../../../UI/radiobuttons/LargeRadio/LargeRadio";
import TooltipBtn from "../../../../../UI/tooltips/TooltipBtn/TooltipBtn";
import DefaultInput from "../../../../../UI/inputs/DefaultInput/DefaultInput";
import PhotoListings from "../PhotoListings/PhotoListings";
import DefaultSelect from "../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
import InputSum from "../../../../../UI/inputs/InputSum/InputSum";
import InputQuantity from "../../../../../UI/inputs/InputQuantity/InputQuantity";
import CategoryPopup from "../../../popups/CategoryPopup/CategoryPopup";
import Taxonomy from "../Taxonomy/Taxonomy";
import TextareaDefault from "../../../../../UI/textarea/TextareaDefault/TextareaDefault";

export default {
  name: "StepContent",
  components: {
    TextareaDefault,
    Taxonomy,
    LargeRadio,
    TooltipBtn,
    DefaultInput,
    PhotoListings,
    DefaultSelect,
    InputSum,
    InputQuantity,
    CategoryPopup,
  },

  props: {
    EL: Object,
    editingSign: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      tooltipAddProductActive: true,
      isModalCategoryPopup: false,
      tags: '',
      materials: '',
    }
  },

  methods: {


    changeCategoryPopup(val){
      this.isModalCategoryPopup = val
    },

    enterMaterials(){
      this.materials = this.EL.addArrayItem(this.materials, this.EL.data.arrayPointer.materials)
    },
    enterTags(){
      this.tags = this.EL.addArrayItem(this.tags, this.EL.data.arrayPointer.tags)
    },
  }
}
</script>

<style scoped>
.mw-138 {
  max-width: 138px;
}
</style>
