<template>
  <div class="fragment">
    <div class="content-top-line content-top-line--with-bottom-line">
      <div class="content-tabs content-tabs--separator"></div>
      <div class="content-top-line-wrap">
        <ToggleFilterButton
            :count="countFilterParams"
            @toggleFilter="$emit('toggleFilter')"
            :showFilter="showFilter"/>
      </div>
    </div>
    <div class="light-bg-block">
      <div class="btn-right-block">
        <div v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['account_newAccount'])"></div>
          <router-link :to="$store.getters.GET_PATHS.mainSettingsShopsCreate">
            <MainButton class="btn-fit-content ml-3"
                        :value="$t('etsyListing_addShop.localization_value.value')"
                        :ico="'ico'"
            >
              <template slot="ico">
                <PlusIconSVG class="btn-icon-plus"/>
              </template>
            </MainButton>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
import PlusIconSVG from '../../../../../../../../public/img/UI-group/plus-icon.svg?inline'

export default {
  name: "EtsyListingsHead",
  components: {
    ToggleFilterButton,
    MainButton,
    PlusIconSVG,
  },

  props: {
    showFilter: Boolean,
    countFilterParams: Number,
  },

  data() {
    return {}
  },

  methods: {}
}
</script>

<style scoped lang="scss">
@import "../../../../../../../scss/mixins/mixins";
</style>

