<template>
  <div class="detail-page">
    <div class="detail-page__left">
      <CardLeftBlock
              class="clear-550"
              :backgroundImage="'store'"
      >
        <template slot="body">
          <div class="lblock-card">
            <!--<div class="lblock-card__label">-->
            <!--<div class="lblock-card__label-i bg-blue2">-->
            <!--<div class="lblock-card__label-ico">-->
            <!--<VacationIcon/>-->
            <!--</div>-->
            <!--Vacation-->
            <!--</div>-->
            <!--</div>-->
            <div class="lblock-card__inner">
              <!--<div class="lblock-card__img">-->
                <!--<div class="lblock-card__img-i">-->
                  <!--<img src="/img/trash-group/etsy-listing-img.png" alt="img">-->
                <!--</div>-->
              <!--</div>-->
              <div class="lblock-card__content"
                   v-if="_.has($store.getters.getShopsItem, 'id')">
                <div class="lblock-card__caption">
                  <div class="soft-text"
                       v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate(['etsyTransfer_ShopName'])"></div>
                    {{$t('etsyTransfer_ShopName.localization_value.value')}}
                  </div>
                </div>
                <div class="lblock-card__title">
                  {{$store.getters.getShopsItem.shop_name}}
                </div>
                <div class="lblock-card__title-sub mt-1">
                  {{$store.getters.getShopsItem.shop_param.shop_link}}
                </div>
                <div class="lblock-card__list custom-row">
                  <div class="custom-col custom-col--50 mb-2">
                    <div class="amount-row light"
                         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['etsyTransfer_ShopId'])"></div>
                      {{$t('etsyTransfer_ShopId.localization_value.value')}}
                      <span>
                       {{$store.getters.getShopsItem.id}}
											</span>
                    </div>
                  </div>
                  <div class="custom-col custom-col--50 mb-2">
                    <div class="amount-row light"
                         v-if="_.has($store.getters.getShopsItem, 'shop_param')"
                         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['etsyTransfer_ShopCurrency'])"></div>
                      {{$t('etsyTransfer_ShopCurrency.localization_value.value')}}
                      <span>
												 {{$store.getters.getShopsItem.shop_param.currency_code}}
											</span>
                    </div>
                  </div>
                  <TableUserNameRow
                          v-if="isAdmin"
                          :item="$store.getters.getShopsItem"
                          :colWidth="'custom-col--100'"
                  />

                </div>
              </div>
            </div>
          </div>
        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <EtsyTransferShop
              :countFilterParams="countFilterParams"
              :filterGetParams="filterGetParams"
              @changeTab="(data) => $emit('changeTab', data)"
              @resetFilter="$emit('resetFilter')"
              @changeFilter="data => $emit('changeFilter', data)"
              @showMore="$emit('showMore')"
              @reload="$emit('reload')"
      />
    </div>
  </div>
</template>

<script>
  import CardLeftBlock from "../../../../../coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import EtsyTransferShop from "./EtsyTransferShop/EtsyTransferShop";
  import TableUserNameRow from "../../../../../coreComponents/TableComponents/TableUserNameRow/TableUserNameRow";

  export default {
    name: "EtsyTransferShopUser",
    components: {
      TableUserNameRow,
      CardLeftBlock,
      EtsyTransferShop,
    },

    props: {
      countFilterParams: Number,
      filterGetParams: Object,
    },

    computed: {

    },

    data() {
      return {
        showFilter: false,
      }
    },

    mounted() {

      // if(this.$route.params.id){
        this.$store.dispatch('getShops', this.$route.params.id)
          // .then(() => {
      //
      //     /**
      //      * Get Shop Info From Etsy
      //      */
      //     this.$store.dispatch('getShopListingsInfo', {shop_id: this.$route.params.id}).then(response => {
      //       if(this._.has(response.data.data, 'shop')){
      //         this.$store.commit('addEtsyTransferShopItems', {index: 0, data: response.data.data})
      //       } else {
      //         this.$store.commit('addEtsyTransferShopItems', {index: 0, data: null})
      //       }
      //     })
      //
      //   })
      // }
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },
    }
  }
</script>

<style scoped>
  @media (min-width: 550px) {
    .detail-page__right {
      margin-top: -30px;
    }
  }
</style>
