<template>
  <div>
    <EtsyListingsHead
        :showFilter="showFilter"
        :countFilterParams="countFilterParams"
        @toggleFilter="toggleFilter"
        @filter="$emit('filter')"
        @changeTab="(data) => $emit('changeTab', data)"
        @reload="$emit('reload')"
    />

    <div class="table-filter-wrap">

      <EtsyListingsFilter
          :showFilter="showFilter"
          :filterGetParams="filterGetParams"
          @close="closeFilter"
          @changeFilter="data => $emit('changeFilter', data)"
          @resetFilter="$emit('resetFilter')"
      />

      <EtsyListingsTable
          :countFilterParams="countFilterParams"
          @showMore="$emit('showMore')"
          @resetFilter="$emit('resetFilter')"
          @reload="$emit('reload')"
          @downloadFiles="$emit('downloadFiles')"
      />
    </div>
  </div>
</template>

<script>

  import EtsyListingsHead from "./EtsyListingsHead/EtsyListingsHead";
  import EtsyListingsFilter from "./EtsyListingsFilter/EtsyListingsFilter";
  import EtsyListingsTable from "./EtsyListingsTable/EtsyListingsTable";

  export default {
    name: "EtsyListingsTableUser",

    components: {
      EtsyListingsHead,
      EtsyListingsFilter,
      EtsyListingsTable,
    },

    props: {
      countFilterParams: Number,
      filterGetParams: Object,
    },

    data(){
      return{
        showFilter: false,
      }
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },
    }
  }
</script>

<style scoped>

</style>
