<template>
  <EtsyListingsEditUser
    :EL="EL"
    @save="save({edit: true})"
  />
</template>

<script>
  import EtsyListingsEditUser from "./EtsyListingsEditUser/EtsyListingsEditUser";
  import {EL} from "../../models/EtsyListings";
  import {etsyListingsMixin} from "../../../../../mixins/etsyListingsMixins/etsyListingsMixin";

  export default {
    name: "EtsyListingsEdit",
    components: {
      EtsyListingsEditUser,
    },

    mixins: [etsyListingsMixin],

    data(){
      return{
        EL: new EL,
      }
    },

    mounted() {
      this.getShopInfo()
      // this.getEtsyListingParams()
      // this.getListingInfo()
    },

    methods:{
      getListingInfo(){
        /**
         * Get shop and listing id for data
         */
        let shopId = this.$route.params.shopId
        let listingId = this.$route.params.listingId


        let data = {
          "shop_id": shopId,
          "listing_id": listingId,
        }

        this.EL.setShopId(shopId)
        this.EL.setListingId(listingId)

        this.$store.dispatch('getEtsyListingsItemFromAPI', data).then((response) => {
          let listing = this.getRespData(response)
          this.EL.setListing(listing)
          this.loadingTaxonomyById(listing['listing']['taxonomy_id'], listing)
        }).catch(error => this.createErrorLog(error))
      },

      loadingTaxonomyById(id, listing){
        this.$store.dispatch('getEtsyListingsTaxonomy', `/${id}`).then((response) => {
          this.EL.setTaxonomyFields(this.getRespData(response))
        }).then(() => {
          this.EL.setItem(listing)
        }).catch(error => this.createErrorLog(error))
      },

      getEtsyListingParams(){
        this.$store.dispatch('getEtsyListingsParams', ).then((response) => {
          this.EL.setParams(this.getRespData(response)['params'])
          this.getListingInfo()
        }).catch(error => this.createErrorLog(error))
      },

      getShopInfo(){
        /**
         * Get id etsy shop of the user
         * @type {{shop_id: string}}
         */

        let shopId = this.$route.params.shopId

        let data = {
          "shop_id": shopId
        }

        /**
         * We get shop information and write it down
         */

        this.$store.dispatch('getEtsyListingsShopInfo', data).then((response) => {
          this.EL.setShopInfo(this.getRespData(response))
          this.getEtsyListingParams()
        }).catch(error => this.createErrorLog(error))
      }
    },
  }
</script>

<style scoped>

</style>