<template>
  <div class="custom-row">
    <div class="custom-col"
      v-for="(item, index) in EL.data.taxonomyFields"
      :key="index"
    >

      <template v-if="item['possibleValues'].length > 0">
        <div class="fragment" v-if="item['isMultivalued']" :key="ran">
          <MultiSelect
              :options="item['possibleValues']"
              :label="item['displayName']"
              :optionsLabel="'name'"
              :maxLimit="item['maximumValuesAllowed']"
              :selected="item['selectedValues']"
              @change="(val) => changeFieldMulti(val, item, index)"
          />
          <!--            :selected="permissions"-->
        </div>
        <div class="fragment" v-else>
          <DefaultSelect
              :options="item['possibleValues']"
              :label="item['displayName']"
              :optionsLabel="'name'"
              :selected="item['selectedValues']"
              @change="(val) => changeField(val, item, index)"
          />
          <!--          v-bind:class="{'ui-no-valid': productItem.validation.proformCategory}"-->
          <!--          :error="productItem.validation.proformCategory"-->
          <!--          :errorTxt="serverError ? productItem.validationTxt.proformCategory : $t(`${productItem.validationTranslate.proformCategory}.localization_value.value`)"-->
          <!--          :otherValue="'proformData'"-->
        </div>
      </template>

      <template v-else-if="item['scales'].length > 0">
        <div class="custom-row ">
          <div class="custom-col custom-col--50 mb-0" v-if="ran">
            <DefaultInput
                :label="item['displayName']"
                :value="_.first(item['values'])"
                @input="(val) => changeInput(val, item, index)"
                :disabled="item['selectedValues'].length === 0"
            />
          </div>
          <div class="custom-col custom-col--50 mb-0">
            <DefaultSelect
                :options="item['scales']"
                :label="'Choose a unit'"
                :optionsLabel="'display_name'"
                @change="(val) => changeFieldScale(val, item, index)"
                :selected="item['scale_name']"
            />
          </div>
        </div>
      </template>

    </div>
  </div>
</template>

<script>
import DefaultSelect from "../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
import MultiSelect from "../../../../../UI/selectiones/MultiSelect/MultiSelect";
import DefaultInput from "../../../../../UI/inputs/DefaultInput/DefaultInput";

export default {
  name: "Taxonomy",
  components: {
    DefaultSelect,
    MultiSelect,
    DefaultInput,
  },
  props:{
    EL: Object,
  },

  data() {
    return {
      ran: 0,
    }
  },

  mounted() {
    this.ran = Math.random()
  },

  methods: {
    changeField(val, item, index){
      if (val === ''){
        this.EL.data.taxonomyFields[index]['selectedValues'] = ''
        return
      }
      let newValue = []
      newValue[0] = val
      this.EL.data.taxonomyFields[index]['selectedValues'] = newValue
    },

    changeFieldScale(val, item, index){
      if (val === ''){
        this.changeInput(val, item, index)
        this.EL.data.taxonomyFields[index]['selectedValues'] = ''
        return
      }
      let newValue = []
      newValue[0] = val
      this.EL.data.taxonomyFields[index]['selectedValues'] = newValue
    },

    changeFieldMulti(val, item, index){
      this.EL.data.taxonomyFields[index]['selectedValues'] = val
    },

    changeInput(val, item, index){
      let newValue = []
      newValue[0] = val
      this.EL.data.taxonomyFields[index]['values'] = newValue
      this.ran = Math.random()
    }
  }
}
</script>

<style scoped>

</style>