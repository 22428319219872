<template>
  <div class="table-filter-wrap">
    <template v-if="$store.getters.getEtsyTransfer.length > 0 && $store.getters.getEtsyTransferLoading !== true">
      <div class="site-table-wrap small-table"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'etsyTransfer_number',
          'etsyTransfer_ListingID',
          'etsyTransfer_Title',
          'etsyTransfer_Price',
          'etsyTransfer_Qty',
          'etsyTransfer_Image',
          'common_manage',
        ])"></div>
        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th></th>
            <th>{{$t('etsyTransfer_number.localization_value.value')}}</th>
            <th>{{$t('etsyTransfer_ListingID.localization_value.value')}}</th>
            <th>{{$t('etsyTransfer_Title.localization_value.value')}}</th>
            <th>{{$t('etsyTransfer_Price.localization_value.value')}}</th>
            <th class="white-space-line">{{$t('etsyTransfer_Qty.localization_value.value')}}</th>
            <th>{{$t('etsyTransfer_Image.localization_value.value')}}</th>
            <th width="100"></th>
            <th>{{$t('common_manage.localization_value.value')}}</th>
          </tr>
          </thead>
          <tbody>

          <tr v-for="(item, index) in $store.getters.getEtsyTransfer" :key="index">
            <td>
              <DefaultCheckbox
                      :selectedNow="selectedNow"
                      :dataValue="item.id"
              />
            </td>
            <td>#{{item.id}}</td>
            <td>
              <div class="table-row">
                <LabelCircle :color="'green'" :value="'A'" class="mr-1"/>
                #{{item.remote_listing_id}}
              </div>
            </td>
            <td>
              <TooltipTextHelper
                      class="max-width-200"
                      :text="item.payload.title"
                      :paragraph="item.payload.title"
              />
            </td>
            <td align="right">
              $ {{item.payload.price}}
            </td>
            <td align="right">
              {{item.payload.quantity}}
            </td>
            <td>
              <div class="table-img">
                <div class="table-img__i">
                  <img v-if="_.has(_.first(item.payload.images), 'url_fullxfull')" :src="_.first(item.payload.images).url_fullxfull" alt="img">
                </div>
              </div>
            </td>
            <td style="width: 100%;"></td>
            <td class="manage-row">
              <div class="table-right table-manage-list table-manage-list--small">

                <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) "
                               :key="$store.getters.getGlobalUpdateManagerButton">
                  <template slot="item">
                    <LinkButton
                            :value="$t('common_delete.localization_value.value')"
                            :type="'delete'"
                            @click.native="removeListing(item.id)"
                    />
                  </template>
                </ManagerButton>
              </div>
            </td>
          </tr>

          </tbody>
        </table>

        <div class="table-card" v-else>
          <div class="table-card__list">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getEtsyTransfer" :key="index">
              <EtsyTransferShopTableMobile
                      :item="item"
                      :selectedNow="selectedNow"
                      :dataValue="item.id"
                      @removeListing="removeListing(item.id)"
              />
            </div>

          </div>
        </div>

        <div class="table-bottom-btn">
          <div class="table-bottom-btn__inner">
            <ShowMore
                    v-if="$store.getters.getEtsyTransferCommonList.next_page_url !== null && $store.getters.getEtsyTransfer.length > 0"
                    @click.native="$emit('showMore')"
                    v-bind:class="{'disabled-btn' : $store.getters.getNextEtsyTransferPage}"
                    :count="$store.getters.getEtsyTransferCommonList.total - $store.getters.getEtsyTransferForPage < $store.getters.getEtsyTransferForPage ?
                    $store.getters.getEtsyTransferCommonList.total - $store.getters.getEtsyTransferForPage:
                    $store.getters.getEtsyTransferForPage"
            />
          </div>
        </div>
      </div>
    </template>

    <template v-if="$store.getters.getEtsyTransferLoading === false && $store.getters.getEtsyTransfer.length === 0">
      <NoResult
              :countFilterParams="countFilterParams"
              :title="$t('common_noDataYet.localization_value.value')"
              :titleWithFilter="$t('common_noResult.localization_value.value')"
              :text="$t('common_noDataYetTxt.localization_value.value')"
              :textWithFilter="$t('common_noResultSeems.localization_value.value')"
              @resetFilter="$emit('resetFilter')"
      />
    </template>

    <!--<ProductsToWarehouse @close="closePopup" v-if="isModalProductsToWarehouse"/>-->


  </div>
</template>

<script>
  import ShowMore from "../../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";
  import {mixinDetictingMobile} from '../../../../../../../../mixins/mobileFunctions'
  import LabelCircle from "../../../../../../../coreComponents/LabelCircle/LabelCircle";
  import NoResult from "../../../../../../../coreComponents/NoResult/NoResult";
  import ManagerButton from "../../../../../../../UI/buttons/ManagerButton/ManagerButton";
  import TooltipTextHelper from "../../../../../../../coreComponents/TooltipTextHelper/TooltipTextHelper";
  import EtsyTransferShopTableMobile from "./EtsyTransferShopTableMobile/EtsyTransferShopTableMobile";
  import DefaultCheckbox from "../../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";


  export default {
    name: "EtsyTransferShopTable",

    components: {
      DefaultCheckbox,
      EtsyTransferShopTableMobile,
      TooltipTextHelper,
      ManagerButton,
      NoResult,
      // MainButton,
      // DefaultInput,
      ShowMore,
      LinkButton,
      LabelCircle,
    },

    mixins: [mixinDetictingMobile],

    props: {
      selectedNow: Boolean,
      countFilterParams: Number,
    },


    // mounted() {
    //   this.isMobile = this.isMobileFunc
    //
    //   window.addEventListener('resize', () => {
    //     this.isMobile = this.isMobileFunc
    //   })
    // },

    data() {
      return {
        orderId: '',
        showFilter: false,
        isMobile: false,

        showContent: false,

        // isModalProductsToWarehouse: false,

        show1: false,
        show2: false,
        show3: false,

        isModalCommentsPopup: '',
        isModalSendEmailPopup: '',
        isModalLastFiveIPPopup: '',
        isModalStatisticPopup: '',
        isModalInfoIDPopup: '',

      }
    },

    methods: {
      closePopup() {
        // this.isModalProductsToWarehouse = false
      },

      removeListing(id) {
        let text = {
          title: 'common_AreYouSure',
          txt: '',
          yes: 'common_confirmDelete',
          no: 'common_confirmNo'
        }

        let confirm = () => {

          this.$store.dispatch('deleteEtsyTransfer', id).then((response) => {
            if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
              this.$emit('reload')
              this.openNotify('success', 'common_notificationRecordDeleted')
            } else {
              setTimeout(() => {
                this.openNotify('error', 'common_notificationUndefinedError')
              }, 200)
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)


      }
    }

  }
</script>

<style lang="scss" scoped>

  /deep/ .max-width-200 .tooltip-helper-full {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
  }

  .site-table thead th,
  .site-table tbody td {
    padding-right: 25px;
    /*padding-left: 5px;*/
  }

  .table-manage-list .manager-btn {
    /*width: 390px;*/
    width: 140px;
  }

  @media(max-width: 1600px) {
    .manage-row,
    .products-table .table-manage-list .manager-btn {
      width: 80px;
    }
  }

  .site-table thead th:first-child,
  .site-table tbody td:first-child {
    /*padding-right: 0;*/
    /*padding-left: 10px;*/
  }

  .site-table thead th:nth-child(2),
  .site-table tbody td:nth-child(2) {
    /*padding-left: 0;*/
  }

  .site-table thead th:last-child,
  .site-table tbody td:last-child {
    padding-right: 0;
  }

  /*.products-table .ellipsis{*/
  /*max-width: 290px;*/
  /*white-space: nowrap;*/
  /*text-overflow: ellipsis;*/
  /*overflow: hidden;*/
  /*display: inherit;*/
  /*}*/

  @media(max-width: 1600px) {
    .hide-1600 {
      display: none;
    }
  }

</style>
