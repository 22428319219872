<template>
  <div class="photo-listings">
    <div class="photo-listings__list"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
          'etsyListing_AddAPhoto',
          'etsyListing_primaryPhoto',
          'etsyListing_everyAngle',
          'etsyListing_details',
          'etsyListing_inUse',
          'etsyListing_sizeAndScale',
          'etsyListing_styledScene',
          'etsyListing_variations'
      ])"></div>
      <div class="photo-listings__item"
           v-for="(item, index) in EL.data.Files.data.downloadFiles"
           :key="index"
      >
        <div class="photo-listings__item-content photo-listings__file">
          <div class="photo-listings__file-img">
            <img :src="item['url_fullxfull']" alt="ico">
          </div>
          <div class="photo-listings__file-delete"
               @click="removeDownloadFile(item, index)"
          ></div>
        </div>
      </div>

      <div class="photo-listings__item"
           v-for="(item, index) in EL.data.Files.data.files"
           :key="index + EL.data.Files.data.downloadFiles.length"
      >
        <div class="photo-listings__item-content photo-listings__file">
          <div class="photo-listings__file-img">
            <img :src="item.url" alt="ico">
          </div>
          <div class="photo-listings__file-delete"
               @click="removeFile(index)"
          ></div>
        </div>
      </div>

      <div class="photo-listings__item"
        v-if="EL.data.Files.data.downloadFiles.length + EL.data.Files.data.files.length <= preview.length"
      >
        <label class="photo-listings__item-content photo-listings__item-add">

          <input type="file"
                 ref="fileInput"
                 :accept="'image/x-png,image/gif,image/jpeg'"
                 multiple @change="changeFile">

          <div class="photo-listings__item-add-img">
            <img src="/img/listings-group/add-photo-icon.svg" alt="ico">
<!--            <AddPhotoIcon/>-->
          </div>
          <div class="photo-listings__item-add-text">
            {{ $t('etsyListing_AddAPhoto.localization_value.value') }}
<!--            Add a Photo-->
          </div>
        </label>
      </div>

      <template
          v-for="(item, index) in preview">
        <div class="photo-listings__item"
             :key="index + 'preview'"
             v-if="index > EL.data.Files.data.downloadFiles.length + EL.data.Files.data.files.length - 1"
        >
          <div class="photo-listings__item-content photo-listings__preview">
            <div class="photo-listings__preview-img">
              <img :src="item.ico" alt="ico" width="80" height="80">
            </div>
            <div class="photo-listings__preview-text">
              {{ $t(item.text + '.localization_value.value') }}
            </div>
          </div>
        </div>
      </template>

    </div>
  </div>
</template>

<script>
  // import AddPhotoIcon from "../../../../../../../public/img/listings-group/add-photo-icon.svg?inline"

  export default {
    name: "PhotoListings",
    components: {
      // AddPhotoIcon,
    },

    props: {
      EL: Object
    },

    data() {
      return {
        files: [],

        preview: [
          {
            ico: '/img/listings-group/1.svg',
            text: 'etsyListing_primaryPhoto',
            // text: 'Primary Photo',
          },
          {
            ico: '/img/listings-group/2.svg',
            text: 'etsyListing_everyAngle',
            // text: 'Every angle',
          },
          {
            ico: '/img/listings-group/3.svg',
            text: 'etsyListing_everyAngle',
            // text: 'Every angle',
          },
          {
            ico: '/img/listings-group/4.svg',
            text: 'etsyListing_everyAngle',
            // text: 'Every angle',
          },
          {
            ico: '/img/listings-group/5.svg',
            text: 'etsyListing_details',
            // text: 'Details',
          },
          {
            ico: '/img/listings-group/6.svg',
            text: 'etsyListing_inUse',
            // text: 'In use',
          },
          {
            ico: '/img/listings-group/7.svg',
            text: 'etsyListing_sizeAndScale',
            // text: 'Size and scale',
          },
          {
            ico: '/img/listings-group/8.svg',
            text: 'etsyListing_styledScene',
            // text: 'Styled scene',
          },
          {
            ico: '/img/listings-group/9.svg',
            text: 'etsyListing_variations',
            // text: 'Variations',
          },
        ],

      }
    },

    methods: {
      changeFile(e) {
        let files = e.target.files;

        Object.keys(files).map(item => {

          let data = {
            item: files[item],
            url: URL.createObjectURL(files[item])
          }

          if(this.EL.data.Files.data.files.length + this.EL.data.Files.data.downloadFiles.length === 10) return

          this.files.push(data)
          this.EL.data.Files.data.files.push(data)
        })

        this.$refs.fileInput.value = ''
      },

      removeDownloadFile(item, index) {
        this.EL.data.Files.removeDownloadFile(index)
        let array = this.EL.getDeleteImageIds()
        array.push(item['listing_image_id'])
        this.EL.setDeleteImageIds(array)
      },

      removeFile(index) {
        this.EL.data.Files.data.files.splice(index, 1)
      },
    }
  }
</script>

<style lang="scss">
  @import "../../../../../../scss/colors";
  @import "../../../../../../scss/mixins/mixins";

  .photo-listings {
    &__list {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -6px;
    }

    &__item {
      padding: 0 6px;
      margin-bottom: 12px;
      position: relative;
    }

    &__item-content {
      max-width: 120px;
      min-width: 120px;
      width: 100%;
      height: 120px;
      border-radius: 5px;
      display: flex;
      flex-direction: column;

      @include for-768{
        max-width: 75px;
        min-width: 75px;
        height: 75px;
      }
    }

    &__item-add {
      border: 2px solid $borderBrown;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: 120px;
      cursor: pointer;
      transition: .15s;

      @include for-768{
        height: 75px;
      }

      &:hover {
        background: $mainBg;
      }


      input[type=file] {
        display: none;
      }
    }


    &__item-add-img {
      margin-bottom: 12px;

      @include for-768{
        margin-bottom: 0;
        transform: scale(0.7);
      }
    }

    &__item-add-text {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: $brown;
      text-align: center;

      @include for-768{
        font-size: 12px;
        line-height: normal;
      }
    }

    &__preview {
      background: $mainBg;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &__preview-img {

      img{
        @include for-768{
          max-width: 40px;
          min-width: 40px;
          height: 40px;
        }
      }
    }

    &__preview-text {
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      color: $borderBrown;

      @include for-768{
        font-size: 10px;
        line-height: normal;
      }
    }

    &__file {

    }

    &__file-img {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;

      img {
        width: 75%;
        height: 75%;
        object-fit: contain;
      }
    }

    &__file-delete {
      position: absolute;
      top: 8px;
      right: 8px;
      width: 14px;
      height: 14px;
      transform: rotate(45deg);
      cursor: pointer;

      &:before {
        content: "+";
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        width: 100%;
        height: 100%;
        color: $brown;
        line-height: 14px;
        font-size: 22px;
        font-weight: bold;
      }

      &:after {
        content: '';
        width: 20px;
        height: 20px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }


  }

</style>
