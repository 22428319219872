<template>
	<div class="detail-page">
		<div class="detail-page__left">
			<CardLeftBlock
					class="clear-550"
					:backgroundImage="'store'"
			>
				<template slot="body">
					<div class="lblock-card" v-if="shopInfo.shop">
						<div class="lblock-card__label" v-if="shopInfo['shop']['is_vacation']">
							<div class="lblock-card__label-i bg-blue2"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['etsyListing_vacation'])"></div>
								<div class="lblock-card__label-ico">
									<VacationIcon/>
								</div>
                {{ $t('etsyListing_vacation.localization_value.value') }}
<!--								Vacation-->
							</div>
						</div>
						<div class="lblock-card__inner">
							<div class="lblock-card__img" v-if="shopInfo.shop['icon_url_fullxfull']">
								<div class="lblock-card__img-i">
									<img :src="shopInfo.shop['icon_url_fullxfull']" alt="img">
								</div>
							</div>
							<div class="lblock-card__content">
								<div class="lblock-card__caption"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['etsyListing_shopName'])"></div>
									<div class="soft-text">
                    {{ $t('etsyListing_shopName.localization_value.value') }}
<!--										Shop Name-->
									</div>
								</div>
								<div class="lblock-card__title">
<!--									Ukrainian Treasures-->
                  {{shopInfo.shop["shop_name"] }}
								</div>
								<div class="lblock-card__list custom-row">
<!--									<div class="custom-col  mb-2">-->
<!--										<div class="amount-row light d-flex flex-column"-->
<!--                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                      <div class="admin-edit" @click="editTranslate(['etsyListing_title'])"></div>-->
<!--                      {{ $t('etsyListing_title.localization_value.value') }}-->
<!--&lt;!&ndash;											Title&ndash;&gt;-->
<!--											<span class="m-0">-->
<!--												{{ shopInfo.shop['title'] }}-->
<!--											</span>-->
<!--										</div>-->
<!--									</div>-->
									<div class="custom-col custom-col--50 mb-2">
										<div class="amount-row light d-flex flex-column"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['etsyListing_shopId'])"></div>
                      {{ $t('etsyListing_shopId.localization_value.value') }}
<!--											Shop ID-->
											<span class="m-0">
												{{ shopInfo.shop['shop_id'] }}
											</span>
										</div>
									</div>
									<div class="custom-col custom-col--50 mb-2">
										<div class="amount-row light d-flex flex-column"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['etsyListing_userId'])"></div>
                      {{ $t('etsyListing_userId.localization_value.value') }}
<!--											User ID-->
											<span class="m-0">
												{{ shopInfo.shop['user_id'] }}
											</span>
										</div>
									</div>
									<div class="custom-col custom-col--50 mb-2">
										<div class="amount-row light d-flex flex-column"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['etsyListing_currency'])"></div>
                      {{ $t('etsyListing_currency.localization_value.value') }}
<!--											Currency-->
											<span class="m-0">
												{{ shopInfo.shop['currency_code'] }}
											</span>
										</div>
									</div>
									<div class="custom-col custom-col--50 mb-2">
										<div class="amount-row light d-flex flex-column"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['etsyListing_url'])"></div>
                      {{ $t('etsyListing_url.localization_value.value') }}
<!--											URL-->
											<a :href="'https://www.etsy.com/shop/' + shopInfo.shop['shop_name']" class="link-btn d-inline-block" target="_blank" rel="nofollow">
                        {{shopInfo.shop["shop_name"] }}
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</template>
			</CardLeftBlock>
		</div>

		<div class="detail-page__right">
			<EtsyListingsShop
        v-if="shopInfo.shop"
        :shopId="shopId"
        :navTabs="navTabs"
        @changeTab="changeTab"
        @showMore="showMore"
        @reload="reload"
      />
		</div>
	</div>
</template>

<script>
	import CardLeftBlock from "../../../../../coreComponents/CardLeftBlock/CardLeftBlock.vue";
	import EtsyListingsShop from "./EtsyListingsShop/EtsyListingsShop";
	import VacationIcon from "../../../../../../../public/img/common/vacation-icon.svg?inline"
  import {routeFilter} from "../../../../../../mixins/routeFilterMixins/routeFilter";
  import {radioButtonsFunc} from "../../../../../../mixins/creationPageMixins/radioButtonsFunc";

	export default {
		name: "EtsyListingsShopUser",
		components: {
			CardLeftBlock,
			EtsyListingsShop,
			VacationIcon,
		},

    mixins: [routeFilter, radioButtonsFunc],

    props: {
      shopInfo: Object,
      shopId: String,
    },

    data(){
		  return{
        forPage: this.$store.getters.getEtsyListingsForPage,
        page: 1,
        onePage: 1,
        filterType: 'active',

        countFilterParams: 0,
        filterGetParams: {},

        navTabs: {
          active: {
            label: this.$t('etsyListing_active.localization_value.value'),
            name: 'active',
            id: 2,
            active: true
          },
          inactive: {
            label: this.$t('etsyListing_inactive.localization_value.value'),
            name: 'inactive',
            id: 2,
            active: false
          },
          draft: {
            label: this.$t('etsyListing_draft.localization_value.value'),
            name: 'draft',
            id: 3,
            active: false
          },
        },
      }
    },

    mounted() {
      if(this.$route.query.type !== undefined){
        this.navTabs.active.active = false
        this.navTabs[this.$route.query.type].active = true
      }
      this.filter()
    },

    methods: {
      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          type: this.$route.query.type,
        }
      },

      filter(next = false) {
        this.getFilterParam()

        // let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        let data = this.generateFilterData(
            generateMainFilter.page,
            generateMainFilter.forPage
        )

        data['status'] = this.$route.query.type ?  this.$route.query.type : this.navTabs.active.name

        if (next) this.$store.commit('setNextEtsyListingsPage', true)
        this.$store.dispatch('getEtsyListingsFromAPI', data).then(() => {
          this.$store.commit('setNextEtsyListingsPage', false)
        })

        this.checkCountFilter([])
      },

      generateFilterData(page, forPage) {
        return {
          shop_id: this.$route.params.shopId,
          limit: forPage,
          page: page
        }
      },

    }
  }
</script>

<style scoped>
	@media (min-width: 550px) {
		.detail-page__right {
			margin-top: -30px;
		}
	}
</style>
