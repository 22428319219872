<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter"
               @changeFilter="changeFilter">
    <template slot="body">
      <div class="table-filter__item">
        <DefaultInput
                :label="'№'"
                :type="'text'"
                v-model="id"
        />
      </div>

      <div class="table-filter__item">
        <DefaultInput
                :label="'Listing ID'"
                :type="'text'"
                v-model="idListing"
        />
      </div>

      <!--<div class="table-filter__item">-->
        <!--<DefaultInput-->
                <!--:label="'Title'"-->
                <!--:type="'text'"-->
                <!--v-model="title"-->
        <!--/>-->
      <!--</div>-->

      <!--<div class="table-filter__item">-->
        <!--<DefaultInput-->
                <!--:label="'Price, $'"-->
                <!--:type="'text'"-->
                <!--v-model="upc"-->
        <!--/>-->
      <!--</div>-->

      <!--<div class="table-filter__item">-->
        <!--<DefaultInput-->
                <!--:label="'Q-ty'"-->
                <!--:type="'text'"-->
                <!--v-model="sku"-->
        <!--/>-->
      <!--</div>-->

    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')"
                  class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
  import FilterBlock from "../../../../../../../coreComponents/FilterBlock/FilterBlock";
  import MainButton from "../../../../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import {filterFunc} from "../../../../../../../../mixins/filterFuncMixin/filterFunc";

  export default {
    name: "EtsyTransferShopFilter",
    components: {
      FilterBlock,
      MainButton,
      DefaultInput,
    },

    props: [
      'showFilter',
      'filterGetParams',
    ],

    mixins: [filterFunc],

    data() {
      return {
        id: this.filterGetParams.id ? this.filterGetParams.id : '',
        idListing: this.filterGetParams.idListing ? this.filterGetParams.idListing : '',
        title: this.filterGetParams.title ? this.filterGetParams.title : '',

        filterCounts: [
          'id',
          'idListing',
          'title',
        ],

      }
    },

    watch: {
      filterGetParams(newVal) {
        this.id = newVal.id ? newVal.id : ''
        this.idListing = newVal.idListing ? newVal.idListing : ''
        this.title = newVal.title ? newVal.title : ''
      },

    },

    methods: {
      closeFilter() {
        this.$emit('close')
      },

      resetFilter() {
        let data = this.resetFilterParams(this, this.filterCounts)
        this.$emit('changeFilter', data)
      },

      changeFilter() {
        let data = this.changeFilterParams(this, this.filterCounts)
        this.$emit('changeFilter', data)
      },
    },
  }
</script>

<style scoped>

</style>
