<template>
  <div class="table-filter-wrap">
    <template v-if="$store.getters.getEtsyListings.length > 0 && $store.getters.getEtsyListingsLoading !== true">
      <div class="site-table-wrap small-table"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'etsyListing_listingId',
          'etsyListing_title',
          'etsyListing_price',
          'etsyListing_qTy',
          'etsyListing_image',
          'common_manage',
          'common_edit',
          'common_delete',
          'common_copy',
          'common_showMore',
        ])"></div>
        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th>{{ $t('etsyListing_listingId.localization_value.value') }}</th>
            <th>{{ $t('etsyListing_title.localization_value.value') }}</th>
            <th class="white-space-line text-right">{{ $t('etsyListing_price.localization_value.value') }}</th>
            <th class="white-space-line text-right">{{ $t('etsyListing_qTy.localization_value.value') }}</th>
            <th>{{ $t('etsyListing_image.localization_value.value') }}</th>
            <th width="100">{{ $t('common_manage.localization_value.value') }}</th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in $store.getters.getEtsyListings"
                :key="index"
                :class="{'site-table__draft-row' : item['state'] === 'draft'}"
            >
              <td>
                <div class="table-row">
                  <LabelCircle :color="'green'" :value="'A'" class="mr-1"
                               v-if="item['state'] === 'active'"
                  />
                  <StatusIcoBtn :type="'cancel'" class="mr-1 pb-1"
                                v-else
                  />

                  <ValueHelper
                      :value="'#' + item['listing_id']"
                  />
                </div>
              </td>
              <td>
                <TooltipTextHelper
                    class="etsy-listings-title"
                    v-if="item['title']"
                   :text="item['title']"
                   :paragraph="item['title']"
                   :styleClass="'white-space-normal style-ellipsis'"
                />
                <ValueHelper
                    v-else
                />
              </td>
              <td align="right">
                <ValueHelper
                    :value="item"
                    :deep="'price.amount'"
                />
              </td>
              <td align="right">
                <ValueHelper
                    :value="item['quantity']"
                />
              </td>
              <td>
                <div class="table-img w-50px" v-if="item['images'] && item['images'].length > 0">
                  <div class="table-img__i">
                    <img :src="item['images'][0]['url_75x75']">
                  </div>
                </div>
                <template v-else>
                  <ValueHelper/>
                </template>
              </td>
              <td class="manage-row">
                <div class="table-right table-manage-list table-manage-list--small">

                  <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) "
                                 :key="$store.getters.getGlobalUpdateManagerButton">
                    <template slot="item">
                      <LinkButton
                          :value="$t('common_edit.localization_value.value')"
                          :type="'edit'"
                          :link="$store.getters.GET_PATHS.mainSettingsEtsyListings + `/${shopId}` + `/${item['listing_id']}`"
                          v-bind:class="{'disabled-btn' : $store.getters.getEtsyListingsBtn}"
                      />
                    </template>
                    <template slot="item">
                      <LinkButton
                          :value="$t('common_delete.localization_value.value')"
                          :type="'delete'"
                          @click.native="removeItem(parseInt(shopId), parseInt(item['listing_id']))"
                          v-bind:class="{'disabled-btn' : $store.getters.getEtsyListingsBtn}"
                      />
                    </template>
                    <template slot="item">
                      <LinkButton
                          :value="$t('common_copy.localization_value.value')"
                          :type="'copy'"
                          @click.native="cloneItem(parseInt(shopId), parseInt(item['listing_id']))"
                          v-bind:class="{'disabled-btn' : $store.getters.getEtsyListingsBtn}"
                      />
                    </template>
                  </ManagerButton>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="table-card" v-if="isMobileFunc()">
          <div class="table-card__list">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getEtsyListings"
                 :key="index"
                 :class="{'draft-row' : item['state'] === 'draft'}"
            >
              <EtsyListingsShopTableMobile
                  :item="item"
                  :shopId="shopId"
                  @removeItem="removeItem(parseInt(shopId), parseInt(item['listing_id']))"
                  @cloneItem="cloneItem(parseInt(shopId), parseInt(item['listing_id']))"
              />
            </div>
          </div>
        </div>

        <div class="table-bottom-btn">
          <div class="table-bottom-btn__inner">
            <ShowMore
                v-if="$store.getters.getEtsyListingsCommonList.pagination.next_offset !== null && $store.getters.getEtsyListings.length > 0"
                @click.native="$emit('showMore')"
                v-bind:class="{'disabled-btn' : $store.getters.getNextEtsyListingsPage}"
                :count="$store.getters.getEtsyListingsForPage"
            />
          </div>
        </div>
      </div>
    </template>

    <template v-if="$store.getters.getEtsyListingsLoading === false && $store.getters.getEtsyListings.length === 0">
      <NoResult
          class="pt-4"
          :countFilterParams="countFilterParams"
          :title="$t('common_noDataYet.localization_value.value')"
          :titleWithFilter="$t('common_noResult.localization_value.value')"
          :text="$t('common_noDataYetTxt.localization_value.value')"
          :textWithFilter="$t('common_noResultSeems.localization_value.value')"
          @resetFilter="$emit('resetFilter')"
      />
    </template>
  </div>
</template>

<script>
import ShowMore from "../../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";
import ManagerButton from "../../../../../../../UI/buttons/ManagerButton/ManagerButton";
import {mixinDetictingMobile} from '../../../../../../../../mixins/mobileFunctions'
import StatusIcoBtn from "../../../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
import LabelCircle from "../../../../../../../coreComponents/LabelCircle/LabelCircle";
import ValueHelper from "../../../../../../../coreComponents/ValueHelper/ValueHelper";
import {etsyListingsMixin} from "../../../../../../../../mixins/etsyListingsMixins/etsyListingsMixin";
import TooltipTextHelper from "../../../../../../../coreComponents/TooltipTextHelper/TooltipTextHelper";
import EtsyListingsShopTableMobile from "./EtsyListingsShopTableMobile/EtsyListingsShopTableMobile";
import NoResult from "../../../../../../../coreComponents/NoResult/NoResult";


export default {
  name: "EtsyListingsShopTable",

  components: {
    TooltipTextHelper,
    ValueHelper,
    ShowMore,
    LinkButton,
    ManagerButton,
    StatusIcoBtn,
    LabelCircle,
    EtsyListingsShopTableMobile,
    NoResult,
  },

  mixins: [mixinDetictingMobile, etsyListingsMixin],

  props: {
    countFilterParams: Number,
    shopId: String,
  },

  data() {
    return {
      orderId: '',
      showFilter: false,
      isMobile: false,
      showContent: false,
    }
  },

  methods: {}

}
</script>

<style lang="scss" scoped>

.site-table thead th,
.site-table tbody td {
  padding-right: 5px;
  padding-left: 5px;
}
.table-manage-list .manager-btn {
  width: 300px;
  //width: 240px;
}
@media (max-width: 1400px) {
  .table-manage-list .manager-btn{
    max-width: 200px;
  }
}

@media(max-width: 1600px) {
  .manage-row,
  .products-table .table-manage-list .manager-btn {
    width: 80px;
  }
}
.site-table thead th:first-child,
.site-table tbody td:first-child {
  padding-right: 0;
  padding-left: 10px;
}
.site-table thead th:last-child,
.site-table tbody td:last-child {
  padding-right: 0;
}

.etsy-listings-title{
  max-width: 350px;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>
