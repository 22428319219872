<template>
  <div class="table-card__item-content"
       :class="{show: show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row">
        <DefaultCheckbox class="mt-1"
                         :selectedNow="selectedNow"
                         :dataValue="dataValue"
        />
        <!--<router-link :to="'/#'">-->
        <div class="table-card__item-number">
          #{{item.id}}
        </div>
        <div class="ml-2 d-flex align-items-center mb-1">
          #{{item.payload.title}}
        </div>
        <!--</router-link>-->
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('etsyTransfer_number.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{item.id}}
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('etsyTransfer_ListingID.localization_value.value')}}
            </div>
            <div class="table-card__item-info d-flex">
              <LabelCircle :color="'green'" :value="'A'" class="mr-1"/>
              #{{item.remote_listing_id}}
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col">
            <div class="table-card__item-label">
              {{$t('etsyTransfer_Title.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{item.payload.title}}
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--33">
            <div class="table-card__item-label">
              {{$t('etsyTransfer_Image.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <div class="table-card__img">
                <div class="table-card__img-i">
                  <img :src="_.first(item.payload.images).url_75x75" alt="img">
                </div>
              </div>
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--33">
            <div class="table-card__item-label">
              {{$t('etsyTransfer_Price.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              $ {{item.payload.price}}
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--33">
            <div class="table-card__item-label">
              {{$t('etsyTransfer_Qty.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{item.payload.quantity}}
            </div>
          </div>
        </div>

        <div class="table-card__item-btn">
          <div class="table-card__item-btn-i">
            <LinkButton
                    :value="$t('common_delete.localization_value.value')"
                    :type="'delete'"
                    @click.native="$emit('removeListing')"
            />
          </div>
        </div>
      </div>

    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
  import LabelCircle from "../../../../../../../../coreComponents/LabelCircle/LabelCircle";
  import LinkButton from "../../../../../../../../UI/buttons/LinkButton/LinkButton";
  import DefaultCheckbox from "../../../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  export default {
    name: "EtsyTransferShopTableMobile",
    components: {DefaultCheckbox, LinkButton, LabelCircle},

    props: {
      item: Object,
      selectedNow: Boolean,
      dataValue: Number,
    },

    data() {
      return {
        show: false,
      }
    }
  }
</script>

<style scoped>

</style>
