<template>
	<EtsyTransferShopUser
					@changeTab="changeTab"
					:countFilterParams="countFilterParams"
					:filterGetParams="filterGetParams"
					@resetFilter="resetFilter"
					@changeFilter="changeFilter"
					@showMore="showMore"
					@reload="reload"
	/>
</template>

<script>
	import EtsyTransferShopUser from "./EtsyTransferShopUser/EtsyTransferShopUser";
	import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";

	export default {
		name: "EtsyTransferShop",
		components: {
			EtsyTransferShopUser,
		},

		mixins: [routeFilter],

		data() {
			return {
				forPage: this.$store.getters.getEtsyTransferForPage,
				page: 1,
				onePage: 1,
				filterId: '',
				filterIdListing: '',
				filterTitle: '',

				countFilterParams: 0,
				filterGetParams: {},

				navTabs: {
				  all: {
				    label: this.$t('etsyTransfer_AllListings.localization_value.value'),
				    name: 'all',
				    id: 1,
				    active: true
				  },
				},
			}
		},

		mounted() {

			if(this.$route.query.type !== undefined){
				this.navTabs.all.active = false
				this.navTabs[this.$route.query.type].active = true
			}

			this.filter()
		},

		methods: {

			getFilterParam() {
				this.filterGetParams = {
					page: this.$route.query.page,
					count: this.$route.query.count,
					id: this.$route.query.id,
					idListing: this.$route.query.idListing,
					title: this.$route.query.title,
				}
			},

			filter(next = false) {
				this.getFilterParam()

				let url

				// generate main filter if has get params in URL
				let generateMainFilter = this.generateMainFilterFromUrl(next, this)

				// generate other filter if has get params in URL
				this.generateParamsFilterFromUrl(this)

				// Generate filter URL
				url = this.generateFilterUrl(
								generateMainFilter.page,
								generateMainFilter.forPage
				)

				this.$store.commit('setEtsyTransferFilter', window.location.search)

				if (next) this.$store.commit('setNextEtsyTransferPage', true)
				this.$store.dispatch('fetchEtsyTransfer', url).then(() => {
					this.$store.commit('setNextEtsyTransferPage', false)
				})


				this.checkCountFilter(['userName'])
			},

			generateFilterUrl(page, forPage) {
				let myQuery = this.createQueryBuilderObject()

				myQuery.where('shop_id', this.$route.params.id)
				// myQuery.where('shop_id', 2820)

				this.generateFilterQueryParams(
								myQuery,
								{
									filterId: 'id',
									filterIdListing: 'listing_id',
								},
				)

				return this.generateGetParamsFromQuery(myQuery, forPage, page)
			},

		}


	}
</script>

<style scoped>

</style>
