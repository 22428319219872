<template>
  <div>
    <EtsyListingsShopHead
        :countFilterParams="countFilterParams"
        :navTabs="navTabs"
        @changeTab="(data) => $emit('changeTab', data)"
    />

    <div class="table-filter-wrap">

<!--      <EtsyListingsShopFilter-->
<!--          :showFilter="showFilter"-->
<!--          @close="closeFilter"-->
<!--          @changeFilter="changeFilter"-->
<!--          @resetFilter="$emit('resetFilter')"-->
<!--      />-->

      <EtsyListingsShopTable
          :navTabs="navTabs"
          :shopId="shopId"
          @showMore="$emit('showMore')"
          @resetFilter="$emit('resetFilter')"
          @reload="$emit('reload')"
      />
<!--          :etsyListingsObj="etsyListingsObj"-->
    </div>
  </div>
</template>

<script>


  import EtsyListingsShopHead from "./EtsyListingsShopHead/EtsyListingsShopHead";
  import EtsyListingsShopTable from "./EtsyListingsShopTable/EtsyListingsShopTable";
  // import EtsyListingsShopFilter from "./EtsyListingsShopFilter/EtsyListingsShopFilter";

  export default {
    name: "EtsyListingsShop",

    components: {
      EtsyListingsShopHead,
      EtsyListingsShopTable,
      // EtsyListingsShopFilter,
    },

    props: {
      countFilterParams: Number,
      filterGetParams: Object,
      shopId: String,
      navTabs: Object,
    },

    data(){
      return{
        etsyListingsObj:{},
      }
    },

    watch: {
      filterGetParams(newVal) {
        this.filterGetParams = newVal
      },
      countFilterParams(newVal) {
        this.countFilterParams = newVal
      },
    },

    mounted() {
      // let data = {
      //   "shop_id": this.shopId,
      // }
      //
      // this.$store.dispatch('getEtsyListingsFromAPI', data).then((response) => {
      //   this.etsyListingsObj = this.getRespData(response)
      // })
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },

      changeFilter(data) {
        this.$emit('changeFilter', data)
      },

      selectAll(val) {
        this.selectedNow = val
      },

      changeTab(id) {
        this.activeTab = id
      },
    }
  }
</script>

<style scoped>

</style>


