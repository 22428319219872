<template>
  <div>
    <EtsyTransferHead
            :showFilter="showFilter"
            :countFilterParams="countFilterParams"
            @toggleFilter="toggleFilter"
            @filter="$emit('filter')"
            @changeTab="(data) => $emit('changeTab', data)"
            @reload="$emit('reload')"
    />

    <div class="table-filter-wrap">

      <EtsyTransferFilter
              :showFilter="showFilter"
              :filterGetParams="filterGetParams"
              @close="closeFilter"
              @changeFilter="data => $emit('changeFilter', data)"
              @resetFilter="$emit('resetFilter')"
      />

      <EtsyTransferTable
              :countFilterParams="countFilterParams"
              @showMore="$emit('showMore')"
              @resetFilter="$emit('resetFilter')"
              @reload="$emit('reload')"
              @downloadFiles="$emit('downloadFiles')"
      />
    </div>
  </div>
</template>

<script>

  import EtsyTransferHead from "./EtsyTransferHead/EtsyTransferHead";
  import EtsyTransferFilter from "./EtsyTransferFilter/EtsyTransferFilter";
  import EtsyTransferTable from "./EtsyTransferTable/EtsyTransferTable";

  export default {
    name: "EtsyTransferTableUser",

    components: {
      EtsyTransferHead,
      EtsyTransferFilter,
      EtsyTransferTable,
    },

    props: {
      countFilterParams: Number,
      filterGetParams: Object,
    },

    data(){
      return{
        showFilter: false,
      }
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },
    }
  }
</script>

<style scoped>

</style>
