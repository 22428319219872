<template>
	<EtsyListingsShopUser
    :shopInfo="shopInfo"
    :shopId="shopId"
  />
</template>

<script>
	import EtsyListingsShopUser from "./EtsyListingsShopUser/EtsyListingsShopUser";

	export default {
		name: "EtsyListingsShop",
		components: {
			EtsyListingsShopUser,
		},

    data(){
		  return {
        shopInfo: {},
        shopId: '',
      }
    },

    mounted() {

      /**
       * Get id etsy shop of the user
       * @type {{shop_id: string}}
       */

      this.shopId = this.$route.params.shopId

      let data = {
        "shop_id": this.shopId
      }

      /**
       * We get shop information and write it down
       */

      this.$store.dispatch('getEtsyListingsShopInfo', data).then((response) => {
        if (this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
          this.shopInfo = this.getRespData(response)
        } else {
          this.$router.push(this.$store.getters.GET_PATHS.mainSettingsEtsyListings);
          setTimeout(() => {
            this.openNotify('error', 'etsyListing_shopError')
          }, 1000);
        }
      })
    },
	}
</script>

<style scoped>

</style>
