<template>
  <div class="fragment">

    <div class="content-top-line content-top-line--with-bottom-line ">
      <div class="content-tabs content-tabs--separator">
        <!--<div class="content-tabs__item" v-bind:class="{active: tab === 1}" @click="changeTab(1)">-->
          <!--Active Listings-->
        <!--</div>-->
      </div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton
                :count="countFilterParams"
                :showFilter="showFilter"
                @toggleFilter="$emit('toggleFilter')"
        />
      </div>
    </div>

    <div class="light-bg-block">
      <div class="btn-left-block ">
        <div class="btn-left-block-i">
          <DefaultCheckbox
                  :label="$t('common_selectAll.localization_value.value')"
                  @input="(val) => $emit('selectAll', val)"
          />
        </div>
        <div class="btn-left-block-i">

        </div>
      </div>

      <div class="btn-right-block"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['etsyTransfer_DownloadFfromEtsy','etsyTransfer_TransferTo'])"></div>


        <MainButton class="btn-fit-content ml-2"
                    :value="$t('etsyTransfer_DownloadFfromEtsy.localization_value.value')"
                    :ico="true"
                    @click.native="getListingForDownload()"
        >
          <template slot="ico">
            <TransferIconSVG class="btn-icon-plus" />
          </template>
        </MainButton>

        <MainButton class="btn-fit-content ml-2"
                    :value="$t('etsyTransfer_TransferTo.localization_value.value')"
                    :ico="true"
                    @click.native="changeUploadListingsPopup(true)"
        >
          <template slot="ico">
            <TransferIconSVG class="btn-icon-plus" />
          </template>
        </MainButton>
      </div>
    </div>

    <UploadListingsPopup
      v-if="uploadListingsPopup"
      :shopId="$route.params.id"
      @close="changeUploadListingsPopup(false)"
    />

  </div>
</template>

<script>
  import MainButton from "../../../../../../../UI/buttons/MainButton/MainButton";
  import ToggleFilterButton from "../../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
  import DefaultCheckbox from "../../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import TransferIconSVG from '../../../../../../../../../public/img/UI-group/transfer-ico-btn.svg?inline'
  import UploadListingsPopup from "../../../../../popups/UploadListingsPopup/UploadListingsPopup";


  export default {
    name: "EtsyTransferShopHead",
    components: {
      MainButton,
      ToggleFilterButton,
      DefaultCheckbox,
      TransferIconSVG,
      UploadListingsPopup,
    },

    props: {
      showFilter: Boolean,
      countFilterParams: Number,
    },

    created() {

    },

    data() {
      return {
        isShowSearchEngineBlock: false,

        uploadListingsPopup: false,
      }
    },



    methods: {

      selectAll(val) {
        this.$emit('selectAll', val)
      },

      showSearchEngineBlock(){
        this.isShowSearchEngineBlock = !this.isShowSearchEngineBlock;
      },

      changeUploadListingsPopup(val) {
        this.uploadListingsPopup = val
      },

      getListingForDownload() {
        let text = {
          title: 'common_AreYouSure',
          txt: '',
          yes: 'common_yes',
          no: 'common_confirmNo'
        }

        let confirm = () => {

          let data = {
            'shop_id': this.$route.params.id,
            'download': true
          }

          this.$store.dispatch('getListingsForDownload', data).then((response) => {
            if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
              this.$emit('reload')
              this.openNotify('success', 'common_notificationRecordChanged')
            } else {
              setTimeout(() => {
                this.openNotify('error', 'common_notificationUndefinedError')
              }, 200)
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)


      },



    }
  }
</script>

<style scoped>
  @media (min-width: 680px) {
    .btn-left-block{
      flex-wrap: wrap;
      max-width: 50%;
    }

    .btn-left-block-i{
      margin-left: 0!important;
      margin-right: 15px;
    }
  }

  @media (max-width: 680px){
    .btn-right-block{
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    .btn-right-block .btn-fit-content + .btn-fit-content{
      margin-top: 10px;
    }

    .btn-right-block .btn-right-block-i{
      /*margin-top: 15px;*/
      margin-right: 0!important;
    }

    .btn-right-block .btn-right-block-i:last-child{
      margin-top: 0;
    }

    .btn-left-block{
      display: flex;
      flex-direction: column;
      max-width: 50%
    }

    .btn-left-block-i{
      margin-left: 10px;
      margin-bottom: 10px;
    }

    .btn-left-block-i:last-child{
      margin-top: 0;
      margin-bottom: 0;
    }

    .btn-left-block-i--link{
      font-size: 12px;
    }

    .light-bg-block{
      align-items: flex-end;
      width: calc(100% + 30px);
      margin: 0 -15px;
    }
  }
</style>

