import {checkValid} from "../../../globalModels/functions/ModelsValidate";
import {Files} from "../../../globalModels/Files";

const _ = require('lodash');


export function EL() {

  this.checkValid = checkValid

  this.data = {
    userId: '',
    user: '',

    shopId: '',
    listingId: '',
    title: '',
    description: '',
    price: '',
    quantity: '',
    taxonomyId: '',
    whoMade: '',
    status: '',
    isSupply: '',
    whenMade: '',
    shippingTemplateSelected: '',
    shippingTemplateId: '',
    shippingProfileSelected: '',
    shippingProfileId: '',
    shopSectionId: '',
    shouldAutoRenew: '',
    isDigital: '',
    itemWeightUnit: '',
    itemDimensionsUnit: '',
    itemWeight: '',
    itemLength: '',
    itemWidth: '',
    itemHeight: '',
    sku: '',

    tags: [],
    materials: [],
    arrayPointer: {
      tags: 'tags',
      materials: 'materials'
    },

    Files: new Files({
      maxCountFiles: 5,
      maxSizeFiles: 3
    }),
    deleteImageIds: [],

    params: {},
    categoryObj: {},
    categoryObjToString: '',
    listing: {},
    taxonomy: {},
    taxonomyFields: {},
    taxonomyResponse: {},
    taxonomyObj: {},
    shopInfo: {},
  }

  this.validation = {
    title: false,
    description: false,
    price: false,
    quantity: false,
    taxonomyId: false,
    whoMade: false,
    status: false,
    isSupply: false,
    whenMade: false,
    shippingTemplateId: false,
    shippingProfileId: false,
    shopSectionId: false,
    shouldAutoRenew: false,
    isDigital: false,
    itemWeightUnit: false,
    itemDimensionsUnit: false,
    itemWeight: false,
    itemLength: false,
    itemWidth: false,
    itemHeight: false,
    sku: false,


    categoryObjToString: false,
  }

  this.validationTranslate = {
    title: '',
    description: '',
    price: '',
    quantity: '',
    taxonomyId: '',
    whoMade: '',
    status: '',
    isSupply: '',
    whenMade: '',
    shippingTemplateId: '',
    shippingProfileId: '',
    shopSectionId: '',
    shouldAutoRenew: '',
    isDigital: '',
    itemWeightUnit: '',
    itemDimensionsUnit: '',
    itemWeight: '',
    itemLength: '',
    itemWidth: '',
    itemHeight: '',
    sku: '',


    categoryObjToString: '',
  }

  this.validationTxt = {
    title: false,
    description: false,
    price: false,
    quantity: false,
    taxonomyId: false,
    whoMade: false,
    status: false,
    isSupply: false,
    whenMade: false,
    shippingTemplateId: false,
    shippingProfileId: false,
    shopSectionId: false,
    shouldAutoRenew: false,
    isDigital: false,
    itemWeightUnit: false,
    itemDimensionsUnit: false,
    itemWeight: false,
    itemLength: false,
    itemWidth: false,
    itemHeight: false,
    sku: false,


    categoryObjToString: false,
  }

  /**
   * Getters
   */

  this.getUserId = () => {
    return this.data.userId
  }

  this.getShopId = () => {
    return this.data.shopId
  }
  this.getListingId = () => {
    return this.data.listingId
  }
  this.getTitle = () => {
    return this.data.title
  }
  this.getDescription = () => {
    return this.data.description
  }
  this.getPrice = () => {
    return this.data.price
  }
  this.getQuantity = () => {
    return this.data.quantity
  }
  this.getTaxonomyId = () => {
    return this.data.taxonomyId
  }
  this.getWhoMade = () => {
    return this.data.whoMade
  }
  this.getStatus = () => {
    return this.data.status
  }
  this.getIsSupply = () => {
    return this.data.isSupply
  }
  this.getWhenMade = () => {
    return this.data.whenMade
  }
  this.getShippingTemplateSelected = () => {
    return this.data.shippingTemplateSelected
  }
  this.getShippingTemplateId = () => {
    return this.data.shippingTemplateId
  }
  this.getShippingProfileSelected = () => {
    return this.data.shippingProfileSelected
  }
  this.getShippingProfileId = () => {
    return this.data.shippingProfileId
  }
  this.getShopSectionId = () => {
    return this.data.shopSectionId
  }
  this.getShouldAutoRenew = () => {
    return this.data.shouldAutoRenew
  }
  this.getIsDigital = () => {
    return this.data.isDigital
  }
  this.getItemWeightUnit = () => {
    return this.data.itemWeightUnit
  }
  this.getItemDimensionsUnit = () => {
    return this.data.itemDimensionsUnit
  }
  this.getItemWeight = () => {
    return this.data.itemWeight
  }
  this.getItemLength = () => {
    return this.data.itemLength
  }
  this.getItemWidth = () => {
    return this.data.itemWidth
  }
  this.getItemHeight = () => {
    return this.data.itemHeight
  }
  this.getSku = () => {
    return this.data.sku
  }
  this.getTags = () => {
    return this.data.tags
  }
  this.getMaterials = () => {
    return this.data.materials
  }
  this.getDeleteImageIds = () => {
    return this.data.deleteImageIds
  }
  this.getParams = () => {
    return this.data.params
  }
  this.getCategoryObj = () => {
    return this.data.categoryObj
  }
  this.getCategoryObjToString = () => {
    return this.data.categoryObjToString
  }
  this.getListing = () => {
    return this.data.listing
  }
  this.getTaxonomy = () => {
    return this.data.taxonomy
  }
  this.getTaxonomyResponse = () => {
    return this.data.taxonomyResponse
  }
  this.getTaxonomyFields = () => {
    return this.data.taxonomyFields
  }
  this.getTaxonomyObj = () => {
    return this.data.taxonomyObj
  }
  this.getShopInfo = () => {
    return this.data.shopInfo
  }

  /**
   * Setters
   */

  this.setUserId = (val) => {
    this.data.userId = val
  }

  this.setShopId = (val) => {
    this.data.shopId = val
  }
  this.setListingId = (val) => {
    this.data.listingId = val
  }
  this.setTitle = (val) => {
    this.data.title = val
  }
  this.setDescription = (val) => {
    this.data.description = val
  }
  this.setPrice = (val) => {
    this.data.price = val
  }
  this.setQuantity = (val) => {
    this.data.quantity = val
  }
  this.setTaxonomyId = (val) => {
    this.data.taxonomyId = val
  }
  this.setWhoMade = (val) => {
    this.data.whoMade = val
  }
  this.setStatus = (val) => {
    this.data.status = val
  }
  this.setIsSupply = (val) => {
    this.data.isSupply = val
  }
  this.setWhenMade = (val) => {
    this.data.whenMade = val
  }
  this.setShippingTemplateSelected = (val) => {
    this.setShippingTemplateId(val['shipping_template_id'] ? val['shipping_template_id'] : '')
    this.data.shippingTemplateSelected = val
  }
  this.setShippingTemplateId = (val) => {
    this.data.shippingTemplateId = val
  }
  this.setShippingProfileSelected = (val) => {
    this.setShippingProfileId(val['shipping_profile_id'] ? val['shipping_profile_id'] : '')
    this.data.shippingProfileSelected = val
  }
  this.setShippingProfileId = (val) => {
    this.data.shippingProfileId = val
  }
  this.setShopSectionId = (val) => {
    this.data.shopSectionId = val
  }
  this.setShouldAutoRenew = (val) => {
    this.data.shouldAutoRenew = val
  }
  this.setIsDigital = (val) => {
    this.data.isDigital = val
  }
  this.setItemWeightUnit = (val) => {
    this.data.itemWeightUnit = val
  }
  this.setItemDimensionsUnit = (val) => {
    this.data.itemDimensionsUnit = val
  }
  this.setItemWeight = (val) => {
    this.data.itemWeight = val
  }
  this.setItemLength = (val) => {
    this.data.itemLength = val
  }
  this.setItemWidth = (val) => {
    this.data.itemWidth = val
  }
  this.setItemHeight = (val) => {
    this.data.itemHeight = val
  }
  this.setSku = (val) => {
    this.data.sku = val
  }
  this.setTags = (val) => {
    this.data.tags = val
  }
  this.setMaterials = (val) => {
    this.data.materials = val
  }
  this.setDeleteImageIds = (val) => {
    this.data.deleteImageIds = val
  }
  this.setParams = (val) => {
    this.data.params = val
  }
  this.setCategoryObj = (val) => {

    let data = {}
    let name = ''

    Object.keys(val).map((item, index) => {
      if (val[item]['name']) {
        data[index] = val[item]['name']
      }
    })

    Object.keys(data).map((item, index) => {
      if (index !== 0) {
        name = name + ` > `
      }
      name = name + `${data[item]}`
    })

    this.setCategoryObjToString(name)
    this.data.categoryObj = val
  }
  this.setCategoryObjToString = (val) => {
    this.data.categoryObjToString = val
  }
  this.setListing = (val) => {
    this.setCategoryObj(val['current_taxonomy'])
    this.data.listing = val
  }
  this.setTaxonomy = (val) => {
    this.data.taxonomy = val
  }
  this.setTaxonomyFields = (val) => {
    this.data.taxonomyFields = this.generateTaxonomyFields(val)
  }
  this.setTaxonomyResponse = (val) => {
    this.data.taxonomyResponse = val
  }
  this.setTaxonomyObj = (val) => {
    this.data.taxonomyObj = val
  }
  this.setShopInfo = (val) => {
    this.data.shopInfo = val
  }
}


/**
 * Global Setters
 */

EL.prototype.setItem = function (val) {
  let listingAttributes = val['listing']['Attributes']

  /**
   * Taxonomy set into Taxonomy Fields
   */
  let taxonomyFields = this.data.taxonomyFields;
  taxonomyFields.map((item) => {
    let attr = _.find(listingAttributes, {'property_id': item['property_id']})

    if (attr) {
      item['selectedValues'] = [] // Convert string to array due to v-select
      attr['values'].map((selected) => {
        item['selectedValues'].push(
            _.find(item['possibleValues'], {'name': selected})
        )
      })
      item['property_id'] = attr['property_id']
      item['scale_id'] = attr['scale_id']

      if (attr['scale_name']) {
        item['scale_name'] = attr['scale_name']
        item['values'] = attr['values']
      }
    }
  })

  /**
   * Set images from api
   */

  let listingImages = val['listing']['images']
  this.data.Files.setDownloadFiles(listingImages)

  /**
   * Set params from api
   */

  let listing = val['listing']

  this.setTitle(listing['title'] ? listing['title'] : '')
  this.setDescription(listing['description'] ? listing['description'] : '')
  this.setPrice(listing['price']?.amount ? listing['price'].amount : '')
  this.setQuantity(listing['quantity'] ? listing['quantity'] : 1)
  this.setTaxonomyId(listing['taxonomy_id'] ? listing['taxonomy_id'] : '')
  this.setWhoMade(listing['who_made'] ? listing['who_made'] : '')

  /**
   * Different statuses for the inactive come from the api.
   * 'active' === 'active, 'edit' === 'inactive'
   **/
  listing['state'] === 'edit' ?
      this.setStatus(this.getParams()['status']['inactive']) :
      this.setStatus(listing['state'] ? listing['state'] : '')


  /**
   * Api returns boolean values as a string
   */
  switch (listing['is_supply']) {
    case 'true':
      this.setIsSupply(true)
      break
    case 'false':
      this.setIsSupply(false)
      break
  }

  /** Creation select **/
  let whenMade = this.getParams()['when_made'][listing['when_made']]
  this.setWhenMade(whenMade ? whenMade : '')

  /**
   * Shipping template field
   * Checking or receiving a number that is not in the parameters
   **/
  if (listing['shipping_template_id']) {
    let shipTempObj = this.getShopInfo()['shippingTemplates']['results']
    let shipTempFlag = false
    Object.values(shipTempObj).map((item) => {
      if (item['shipping_template_id'] === listing['shipping_template_id']) {
        shipTempFlag = true
        this.setShippingTemplateSelected(item)
      }
    })
    this.setShippingTemplateId(shipTempFlag ? listing['shipping_template_id'] : '')
  } else {
    this.setShippingTemplateId('')
  }

  this.setShopSectionId(listing['shop_section_id'] ? listing['shop_section_id'] : '')
  this.setShouldAutoRenew(listing['should_auto_renew'])
  this.setIsDigital(listing['is_digital'])
  this.setItemWeightUnit(listing['item_weight_unit'] ? listing['item_weight_unit'] : '')
  this.setItemDimensionsUnit(listing['item_dimensions_unit'] ? listing['item_dimensions_unit'] : '')
  this.setItemWeight(listing['item_weight'] ? listing['item_weight'] : '')
  this.setItemLength(listing['item_length'] ? listing['item_length'] : '')
  this.setItemWidth(listing['item_width'] ? listing['item_width'] : '')
  this.setItemHeight(listing['item_height'] ? listing['item_height'] : '')
  this.setSku(_.first(listing['sku']) ? _.first(listing['sku']) : '')
  this.setTags(listing['tags'] ? listing['tags'] : [])
  this.setMaterials(listing['materials'] ? listing['materials'] : [])
  if (listing?.shipping_profile_id && this.getShopInfo()?.shippingTemplates && this.getShopInfo()?.shippingTemplates?.length > 0) {
    let currentShippingProfile = this.getShopInfo()?.shippingTemplates.find(searchItem => {
      return +searchItem.shipping_profile_id === +listing.shipping_profile_id
    })
    this.setShippingProfileSelected(currentShippingProfile)
  }
}


/**
 * Functions
 */

/**
 * Get a taxonomy of what fields should be. taxonomy['attribute_value_sets']
 * After that I write in them the keys from the object taxonomy['node_properties']
 * @param val
 * @returns {*[]}
 */
EL.prototype.generateTaxonomyFields = function (val) {


  let data = val['taxonomy']
  let fields = []
  let nodeProperties = data['node_properties']

  data['attribute_value_sets'].map((item) => {
    fields.push(item)
  })

  fields.map((item) => {
    let nodeProperty = _.find(nodeProperties, {'display_name': item['displayName']})
    if (nodeProperty && nodeProperty["supports_attributes"]) {

      /**
       * Duplicate almost all the fields in order to bind properties
       * and what will be selected and passed in the future
       **/

      item['possibleValues'] = nodeProperty['possible_values']
      item['selectedValues'] = ''
      item['values'] = []
      item['scales'] = nodeProperty['scales']
      item['property_id'] = nodeProperty['property_id']

      if (item['scales'] === nodeProperty['scales']) {
        item['scales'] = nodeProperty['scales']
      }

      if (nodeProperty['is_multivalued']) {
        item['isMultivalued'] = true
      }
    }
  })

  return fields
}

EL.prototype.setAccountUser = function (user) {
  this.setUserId(user.id)
}

/**
 * Adding items to TAG and MATERIALS fields
 * @param val
 * @param arrayPointer
 * @returns {string}
 */
EL.prototype.addArrayItem = function (val, arrayPointer) {
  let newArrayParts = val.split(',')
  let array
  let reg
  let maxIndex
  switch (arrayPointer) {
    case this.data.arrayPointer.tags:
      reg = /[^\p{L}\p{Nd}\p{Zs}\-`™©®]/u
      array = this.getTags().concat(newArrayParts)
      maxIndex = 11
      this.setTags(this.getNewArray(array, reg, maxIndex))
      break;
    case this.data.arrayPointer.materials:
      reg = /[^\p{L}\p{Nd}\p{Zs}]/u
      array = this.getMaterials().concat(newArrayParts)
      maxIndex = 12
      this.setMaterials(this.getNewArray(array, reg, maxIndex))
      break;
  }

  /** After entering, I return an empty value to the input **/
  return ''
}

/**
 * Getting a new and valid array. Passes duplicate, count and regex checks
 * @param array
 * @param reg
 * @param maxIndex
 * @returns {*[]}
 */
EL.prototype.getNewArray = function (array, reg, maxIndex) {
  let result = []
  let objCheck = {}
  let newArray = []


  /**
   * Create an object with keys from the resulting array.
   * In this case, the entire name of the keys is trimmed and brought to the lower case.
   **/
  array.map((item) => {
    let ItemAfterTrim = item.trim()
    if (!objCheck[ItemAfterTrim.toLowerCase()]) {
      objCheck[ItemAfterTrim.toLowerCase()] = ItemAfterTrim
    }
  })

  /**
   * Next, we create a new array using the values of the keys
   */
  Object.values(objCheck).map((item) => {
    newArray.push(item)
  })

  /**
   * In the result array we write the values that are "validated"
   */
  newArray.map((item, index) => {
    if (index > maxIndex ||
        item.length === 0 ||
        reg.test(item)) return
    result.push(item.trim())
  })

  return result
}

/**
 * Removing TAGS and MATERIAL Items from Arrays
 * @param index
 * @param arrayPointer
 */
EL.prototype.removeArrayItem = function (index, arrayPointer) {
  let array
  switch (arrayPointer) {
    case this.data.arrayPointer.tags:
      array = this.getTags()
      array.splice(index, 1)
      this.setTags(array)
      break;
    case this.data.arrayPointer.materials:
      array = this.getMaterials()
      array.splice(index, 1)
      this.setMaterials(array)
      break;
  }
}


/**
 * Confusing logic for generating data to send from a taxonomy.
 * All fields are traversed.
 * To give a sign to an attribute, the field is written "property_id" which we got earlier from "node_properties"
 * If there is a "scales" and "selectedValues" field, we take the value from text fields and select.
 * Detailed analysis is best done with the backend and etsy api documentation
 * @returns {*[]}
 */
EL.prototype.getPropertyAttributes = function () {
  let propertyAttr = []

  this.getTaxonomyFields().map((item) => {

    let multipleSelectArray = []

    let data = {
      "property_id": item['property_id'] ? item['property_id'] : '',
      "value_ids": [],
      "values": [],
    }

    if (item['scale_id']) {
      data["scale_id"] = item['scale_id']
    }

    if (_.find(item['scales']) && _.first(item['selectedValues'])) {
      let selectScale = _.first(item['selectedValues'])
      data["scale_id"] = selectScale['scale_id']
    }

    if (item['selectedValues']) {
      item['selectedValues'].map((selVal) => {
        if (selVal) {
          data['value_ids'].push(selVal['value_id'])
        }
      })

      if (data['value_ids'].length > 1) {
        multipleSelectArray = data['value_ids'].join(',')
        data['value_ids'] = [multipleSelectArray]
      }
    }
    if (item['values']) {
      data["values"] = item['values'] ? item['values'] : []
    }

    if (data['values'].length !== 0 ||
        data['value_ids'].length !== 0) {
      propertyAttr.push(data)
    }
  })

  return propertyAttr
}

EL.prototype.setFiles = function (val) {
  this.data.Files.pushDownloadFiles(val)
}

EL.prototype.removeFile = function (id) {
  let i = -1
  _.findIndex(this.data.Files.getDownloadFiles(), function (item, index) {
    if (item[id] !== undefined) i = index
  });

  this.data.Files.removeDownloadFile(i)
}


/**
 * When creating a listing, we set standard values in the required fields.
 * The value is taken from "getEtsyListingsParams"
 */
EL.prototype.setDefaultRadioValue = function () {
  this.setStatus(this.getParams()['status']['active'])
  this.setIsDigital(false)
  this.setWhoMade(this.getParams()['who_made']['i_did']['value'])
  this.setIsSupply(this.getParams()['is_supply']['finished_product']['value'])
  this.setShouldAutoRenew(this.getParams()['should_auto_renew']['manual']['value'])
  this.setItemWeightUnit(this.getParams()['item_weight_unit']['oz'])
  this.setItemDimensionsUnit(this.getParams()['item_dimensions_unit']['in'])
  this.setQuantity(1)

  if(this.getShopInfo()?.shippingTemplates && this.getShopInfo()?.shippingTemplates?.length > 0) {
    this.setShippingProfileSelected(_.first(this.getShopInfo()['shippingTemplates']))
    this.setShippingProfileId(_.first(this.getShopInfo()['shippingTemplates'])['shipping_profile_id'])
  }

  if ( this.getShopInfo()?.shippingTemplates?.results && this.getShopInfo()['shippingTemplates']['results'].length > 0
      && _.first(this.getShopInfo()['shippingTemplates']['results'])['shipping_template_id']) {
    this.setShippingTemplateSelected(_.first(this.getShopInfo()['shippingTemplates']['results']))
    this.setShippingTemplateId(_.first(this.getShopInfo()['shippingTemplates']['results'])['shipping_template_id'])
  }
  if (this.getShopInfo()?.shopSections?.results &&
      this.getShopInfo()['shopSections']['results'].length > 0 && _.first(this.getShopInfo()['shopSections']['results'])['shop_section_id']) {
    this.setShopSectionId(_.first(this.getShopInfo()['shopSections']['results'])['shop_section_id'])
  }
}

/**
 * Validations
 */

EL.prototype.firstValidation = function () {
  let data = this.data


  let validationItems = {
    title: data.title,
    whenMade: data.whenMade,
    description: data.description,
    price: data.price,
    quantity: data.quantity,
    categoryObjToString: data.categoryObjToString,
    // shippingTemplateId: data.shippingTemplateId,
  }


  let validationOptions = {
    title: {type: ['empty']},
    whenMade: {type: ['empty']},
    description: {type: ['empty']},
    price: {type: ['empty']},
    quantity: {type: ['empty', 'not-zero']},
    categoryObjToString: {type: ['empty']},
    // shippingTemplateId: {type: ['empty']},
  }

  return this.checkValid(validationItems, validationOptions)

}

/**
 * Prepare Data
 */

EL.prototype.prepareSave = function () {

  let data = {
    "shop_id": this.getShopId() ? this.getShopId() : '',
    "title": this.getTitle() ? this.getTitle() : '',
    "description": this.getDescription() ? this.getDescription() : '',
    "price": this.getPrice() ? parseFloat(this.getPrice()) : '',
    "quantity": this.getQuantity() ? this.getQuantity() : '',
    "taxonomy_id": this.getTaxonomyId() ? this.getTaxonomyId() : '',
    "who_made": this.getWhoMade() ? this.getWhoMade() : '',
    "is_supply": this.getIsSupply() === 'true' ? true : false,
    "when_made": this.getWhenMade() ? this.getWhenMade()['value'] : '',
    "shipping_template_id": this.getShippingTemplateId() ? this.getShippingTemplateId() : '',
    "shop_section_id": this.getShopSectionId() ? this.getShopSectionId() : null,
    "should_auto_renew": this.getShouldAutoRenew() === 'true' ? true : false,
    "state": this.getStatus() ? this.getStatus() : '',
    "is_digital": this.getIsDigital() === 'true' ? true : false,
    "item_weight_unit": this.getItemWeightUnit() ? this.getItemWeightUnit() : '',
    "item_dimensions_unit": this.getItemDimensionsUnit() ? this.getItemDimensionsUnit() : '',
    "item_weight": this.getItemWeight() ? parseFloat(this.getItemWeight()) : '',
    "item_length": this.getItemLength() ? parseFloat(this.getItemLength()) : '',
    "item_width": this.getItemWidth() ? parseFloat(this.getItemWidth()) : '',
    "item_height": this.getItemHeight() ? parseFloat(this.getItemHeight()) : '',
    "sku": this.getSku() ? [this.getSku()] : [],
    "tags": this.getTags() ? this.getTags() : [],
    "materials": this.getMaterials() ? this.getMaterials() : [],
    "property_attributes": this.getPropertyAttributes(),
  }

  if (this.data.isDigital === false || this.data.isDigital === 'false') {
    data["shipping_profile_id"] = this.getShippingProfileId() ? +this.getShippingProfileId() : ''
  }

  return data
}

EL.prototype.prepareFiles = function () {
  return this.data.Files.getFiles()
}