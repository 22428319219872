<template>
  <modal
      @close="$emit('close')"
      class="custom-popup medium-size"
  >
    <template slot="header">
      <div class="fragment"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['etsyListing_category'])"></div>
        {{ $t('etsyListing_category.localization_value.value') }}
      </div>
    </template>
    <template slot="body">
      <div class="custom-popup__content mb-4">
        <div class="custom-row">
          <div class="custom-col"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_category'])"></div>
            <SearchInput
                :type="'text'"
                :label="$t('etsyListing_categoryLabel.localization_value.value')"
                v-model="search"
                @input.native="functionSearchCategory"
            />


            <div class="pt-2 pb-3" v-if="search.length > 3 && $store.getters.getEtsyListingsLoadingCategory">
              <DotsShadowPreloader/>
            </div>
          </div>
          <!--          <div class="custom-col">-->
          <!--            <SearchSelect-->
          <!--                :options="categories"-->
          <!--                :label="'Start typing category name'"-->
          <!--                :functionSearch="functionSearchCategory"-->
          <!--                :optionsLabel="'Present'"-->
          <!--                @change="selectCategory"-->
          <!--            />-->
          <!--          </div>-->
        </div>
        <div class="custom-row">
          <div class="transaction-info__col justify-content-center">
            <div class="transaction-info__txt"           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['etsyListing_categorySelectOne'])"></div>
              {{ $t('etsyListing_categorySelectOne.localization_value.value') }}
<!--              Select one from list below-->
            </div>
          </div>
          <!--<div class="transaction-info__col align-items-end">-->
          <!--<MainButton-->
          <!--:value="'Add address'"-->
          <!--class="buy-label__btn-i wfc"-->
          <!--@click.native="$emit('openControl')"-->
          <!--/>-->
          <!--</div>-->
        </div>

        <div class="custom-row">
          <div class="custom-col">
            <!--            <pre>-->
            <!--            {{categories}}-->
            <!--            </pre>-->

            <div class="nova-poshta-sender-address mt-3 flex-column">
              <div class="nova-poshta-sender-address__item wfc"
                   ref="categoryItem"
                   v-for="(item, i) in categories[`foundTaxonomies`]"
                   :key="i"
                   @click="selectCategoryFc(item, i)"
              >
                <span v-for="(el, key, j) in item" :key="j">
                  <span v-if="j !== 0 && el.name">
                    &gt;
                  </span>
                  <span>
                    {{ el.name }}
                  </span>
                </span>
              </div>

              <!--              <div class="nova-poshta-sender-address__item wfc">-->
              <!--                Art & Collectibles   >   Drawing & Illustration   >   Digital-->
              <!--              </div>-->
              <!--              <div class="nova-poshta-sender-address__item wfc">-->
              <!--                Art & Collectibles   >   Prints   >   Digital Prints-->
              <!--              </div>-->
              <!--              <div class="nova-poshta-sender-address__item wfc">-->
              <!--                Art & Collectibles   >   Prints   >   Giclée-->
              <!--              </div>-->
            </div>
          </div>
        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="buy-label__btn d-flex align-items-center"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_cancel'])"></div>
        <span
            class="site-link site-link--alt buy-label__btn-i mr-3"
            @click="$emit('close')"
        >
            {{ $t('common_cancel.localization_value.value') }}
        </span>

<!--        <MainButton-->
<!--            :value="'Apply'"-->
<!--            class="buy-label__btn-i"-->
<!--            @click.native="applyCategory"-->
<!--        />-->
      </div>
    </template>
  </modal>

</template>

<script>
import Modal from '../../../../commonModals/Modal.vue'
// import MainButton from '../../../../UI/buttons/MainButton/MainButton.vue'
import SearchInput from "../../../../UI/inputs/SearchInput/SearchInput";
import DotsShadowPreloader from "../../../../coreComponents/preloaders/DotsShadowPreloader/DotsShadowPreloader";
import {etsyListingsMixin} from "../../../../../mixins/etsyListingsMixins/etsyListingsMixin";
// import SearchSelect from "../../../../UI/selectiones/SearchSelect/SearchSelect";

export default {
  name: "CategoryPopup",
  components: {
    Modal,
    // MainButton,
    SearchInput,
    // SearchSelect,
    DotsShadowPreloader,
  },

  mixins: [etsyListingsMixin],

  props: {
    EL: Object
  },

  data() {
    return {
      search: '',
      categories: [],
      selectCategory: {},
    }
  },

  mounted() {

  },

  methods: {

    functionSearchCategory() {
      let data = {
        search: this.search
      }

      if (this.search.length < 3) return

      this.$store.dispatch('getEtsyListingsTaxonomyCategory', data).then((response) => {
        this.categories = this.getRespData(response)
      })
    },

    selectCategoryFc(val) {
      this.selectCategory = val

      let currentItem
      Object.keys(val).map((item) => {
        if(!val[item]['id']) return
        currentItem = val[item]
      })

      this.selectCategoryId(currentItem)
      this.EL.setCategoryObj(this.selectCategory)
    },

    selectCategoryId(item){
      this.$store.dispatch('getEtsyListingsTaxonomy', `/${item.id}`).then((response) => {
        let res = this.getRespData(response)
        this.EL.setTaxonomyFields(res)
        this.EL.setTaxonomyId(res['taxonomy']['id'])
        this.$emit('close')
      }).catch(error => this.createErrorLog(error))
    }

    // applyCategory() {
    //
    // }

  },

}

</script>

<style scoped lang="scss">
@import '../../../../../scss/colors';
.nova-poshta-sender-address {
  display: flex;
  flex-wrap: wrap;
  &__item {
    padding: 5px 12px;
    background: $borderBrown;
    margin-right: 8px;
    margin-bottom: 8px;
    border-radius: 4px;
    transition: 0.3s;
    color: $white;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
    &:hover, &--active {
      background: $brown;
    }
  }
}
</style>
