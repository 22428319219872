<template>
  <EtsyListingsCreateUser
      :EL="EL"
      @save="(val) => save(false, val)"
      @saveDraft="saveDraft"
  />
</template>

<script>
  import EtsyListingsCreateUser from "./EtsyListingsCreateUser/EtsyListingsCreateUser";
  import {etsyListingsMixin} from "../../../../../mixins/etsyListingsMixins/etsyListingsMixin";
  import {EL} from "../../models/EtsyListings";

  export default {
    name: "EtsyListingsCreate",
    components: {
      EtsyListingsCreateUser,
    },

    mixins: [etsyListingsMixin],

    data(){
      return{
        EL: new EL,
      }
    },

    mounted() {
      this.getShopInfo()
      this.getEtsyListingParams()
      this.getShopIdFc()
    },

    methods:{
      getShopIdFc(){
        /**
         * Get shop and listing id for data
         */
        let shopId = this.$route.params.shopId
        this.EL.setShopId(shopId)
      },

      getEtsyListingParams(){
        this.$store.dispatch('getEtsyListingsParams', ).then((response) => {
          this.EL.setParams(this.getRespData(response)['params'])
        }).catch(error => this.createErrorLog(error))
      },

      getShopInfo(){
        /**
         * Get id etsy shop of the user
         * @type {{shop_id: string}}
         */

        let shopId = this.$route.params.shopId

        let data = {
          "shop_id": shopId
        }

        /**
         * We get shop information and write it down
         */

        this.$store.dispatch('getEtsyListingsShopInfo', data).then((response) => {
          this.EL.setShopInfo(this.getRespData(response))
          this.EL.setDefaultRadioValue()
        }).catch(error => this.createErrorLog(error))
      }
    },
  }
</script>

<style scoped>

</style>