<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter"
               @changeFilter="changeFilter">
    <template slot="body">
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['etsyTransfer_ShopId'])"></div>
        <DefaultInput
                :label="$t('etsyTransfer_ShopId.localization_value.value')"
                :type="'text'"
                v-model="id"
        />
      </div>
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['etsyTransfer_ShopName'])"></div>
        <DefaultInput
                :label="$t('etsyTransfer_ShopName.localization_value.value')"
                :type="'text'"
                v-model="name"
        />
      </div>
      <div class="table-filter__item"
           v-if="isAdmin"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_user'])"></div>
        <SearchSelect
                :options="options"
                :label="$t('common_user.localization_value.value')"
                :optionsLabel="'email.contacts[0]'"
                :functionSearch="functionSearchUserFilter"
                :selected="userName"
                :typeSelect="'users'"
                :userEmail="true"
                @change="changeUsersFilter"
        />
      </div>
    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')"
                  class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
  import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
  import {usersFunc} from "../../../../../../../mixins/usersMixins/usersFunc";
  import SearchSelect from "../../../../../../UI/selectiones/SearchSelect/SearchSelect";

  export default {
    name: "EtsyTransferFilter",
    components: {
      SearchSelect,
      FilterBlock,
      MainButton,
      DefaultInput,
    },

    props: {
      showFilter: Boolean,
      filterGetParams: Object,
    },

    mixins: [filterFunc, usersFunc],

    data() {
      return {
        id: this.filterGetParams.id ? this.filterGetParams.id : '',
        name: this.filterGetParams.name ? this.filterGetParams.name : '',

        userName: this.filterGetParams.userName ? this.filterGetParams.userName : '',
        userId: this.filterGetParams.userId ? this.filterGetParams.userId : '',

        filterCounts: [
          'id',
          'name',

          'userName',
          'userId',
        ],

        options: [],
      }
    },

    watch: {
      filterGetParams(newVal) {
        this.name = newVal.name ? newVal.name : ''
        this.userName = newVal.userName ? newVal.userName : ''
        this.userId = newVal.userId ? newVal.userId : ''
      },

    },

    methods: {
      closeFilter() {
        this.$emit('close')
      },

      resetFilter() {
        let data = this.resetFilterParams(this, this.filterCounts)
        this.$emit('changeFilter', data)
      },

      changeFilter() {
        let data = this.changeFilterParams(this, this.filterCounts)
        this.$emit('changeFilter', data)
      },
    },
  }
</script>

<style scoped>

</style>
