
<template>
  <div class="fragment">
    <template v-if="$store.getters.getShops.length > 0 && $store.getters.getLoadingShops !== true">
    <div class="custom-row pt-4">
      <template v-for="(item, index) in $store.getters.getShops">
        <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--xl-33"
             :key="index"
        >
          <div class="case-cart">
            <div class="case-cart__inner">
              <div class="case-cart__head">
                <div class="case-cart__columns">
                  <div class="case-cart__img-block pl-0 pt-0">
                    <div class="case-cart__img case-cart__img--60">
                      <img :src="item.shop_param.logo_url" alt="img"
                           v-if="item.shop_param.logo_url"
                      >
                      <img v-else src="/img/trash-group/etsy-listing-img.png" alt="img">
                    </div>
                  </div>
                  <div class="case-cart__content-block">
                    <div class="case-cart__title pr-0">
                    <span class="color-brown cursor-initial">
                      {{ item.shop_name }}
                    </span>

                      <div class="case-cart__status">
                        <div class="etsy-letter-img"></div>
                      </div>

                      <div class="case-cart__title-sub">
                        {{ item.shop_param.shop_link }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="case-cart__body">
                <div class="case-cart__row custom-row">
                  <div class="case-cart__col custom-col custom-col--50">
                    <div class="case-cart__label"
                         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['etsyTransfer_ShopId'])"></div>
                      {{$t('etsyTransfer_ShopId.localization_value.value')}}
                    </div>
                    <div class="case-cart__content">
                      {{ item.id }}
                    </div>
                  </div>
                  <div class="case-cart__col custom-col custom-col--50"
                       v-if="item.shop_param.currency_code"
                  >
                    <div class="case-cart__label"
                         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['etsyTransfer_ShopCurrency'])"></div>
                      {{$t('etsyTransfer_ShopCurrency.localization_value.value')}}
                    </div>
                    <div class="case-cart__content">
                      {{item.shop_param.currency_code}}
                    </div>
                  </div>
                  <TableUserNameRow
                          v-if="isAdmin"
                          :item="item"
                          :colWidth="'custom-col--100'"
                  />

                  <!--<div class="case-cart__col custom-col custom-col&#45;&#45;50">-->
                    <!--<div class="case-cart__label"-->
                         <!--v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">-->
                      <!--<div class="admin-edit" @click="editTranslate(['etsyTransfer_ShopName'])"></div>-->
                      <!--{{$t('etsyTransfer_ShopName.localization_value.value')}}-->
                    <!--</div>-->
                    <!--<div class="case-cart__content">-->
                      <!--{{ item.shop_name }}-->
                    <!--</div>-->
                  <!--</div>-->
                  <!--<div class="case-cart__col custom-col custom-col&#45;&#45;50">-->
                    <!--<div class="case-cart__label"-->
                         <!--v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">-->
                      <!--<div class="admin-edit" @click="editTranslate(['etsyTransfer_ActiveListings'])"></div>-->
                      <!--{{$t('etsyTransfer_ActiveListings.localization_value.value')}}-->
                    <!--</div>-->
                    <!--<div class="case-cart__content">-->
                      <!--{{ etsyTransferShopItem.shop.listing_active_count }}-->
                    <!--</div>-->
                  <!--</div>-->
                </div>
              </div>

              <div class="case-cart__bottom">
                <div class="case-cart__btn ml-auto"
                     v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['etsyTransfer_ViewListings'])"></div>
                  <router-link class="color-brown" :to="$store.getters.GET_PATHS.mainSettingsEtsyTransfer + '/shop/' + item.id">
                    <CaseCartButton
                            :value="$t('etsyTransfer_ViewListings.localization_value.value')"
                            :ico="true"
                            :typeIco="'show'"
                    >
                    </CaseCartButton>
                  </router-link>
                </div>
              </div>

            </div>
          </div>
        </div>
      </template>
      <div style="display: none">
        <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--xl-33">
          <div class="case-cart">
            <div class="case-cart__inner">
              <div class="case-cart__head">
                <div class="case-cart__columns">
                  <div class="case-cart__img-block pl-0 pt-0">
                    <div class="case-cart__img case-cart__img--60">
                      <img src="/img/trash-group/etsy-listing-img.png" alt="img">
                    </div>
                  </div>
                  <div class="case-cart__content-block">
                    <div class="case-cart__title pr-0">
                     <span class="color-brown cursor-initial">
                      Ukrainian Treasures
                    </span>

                      <div class="case-cart__status">
                        <div class="etsy-letter-img"></div>
                      </div>

                      <div class="case-cart__title-sub">
                        www.ukitrsures.com
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="case-cart__body">
                <div class="case-cart__row custom-row">
                  <div class="case-cart__col custom-col custom-col--50">
                    <div class="case-cart__label">
                      Shop ID
                    </div>
                    <div class="case-cart__content">
                      4645756
                    </div>
                  </div>
                  <div class="case-cart__col custom-col custom-col--50">
                    <div class="case-cart__label">
                      User ID
                    </div>
                    <div class="case-cart__content">
                      4645756
                    </div>
                  </div>
                  <div class="case-cart__col custom-col custom-col--50">
                    <div class="case-cart__label">
                      Currency
                    </div>
                    <div class="case-cart__content">
                      USD
                    </div>
                  </div>
                  <div class="case-cart__col custom-col custom-col--50">
                    <div class="case-cart__label">
                      Name
                    </div>
                    <div class="case-cart__content">
                      UkiTreasures
                    </div>
                  </div>
                </div>
              </div>
              <div class="case-cart__bottom">
                <div class="case-cart__btn ml-auto">
                  <router-link class="color-brown" :to="$store.getters.GET_PATHS.mainSettingsEtsyTransferShop">
                    <CaseCartButton
                            :value="'View Listings'"
                            :ico="true"
                            :typeIco="'show'"
                    >
                    </CaseCartButton>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--xl-33">
          <div class="case-cart">
            <div class="case-cart__inner">
              <div class="case-cart__head">
                <div class="case-cart__columns">
                  <div class="case-cart__img-block pl-0 pt-0">
                    <div class="case-cart__img case-cart__img--60">
                      <img src="/img/trash-group/etsy-listing-img.png" alt="img">
                    </div>
                  </div>
                  <div class="case-cart__content-block">
                    <div class="case-cart__title pr-0">
                     <span class="color-brown cursor-initial">
                      Ukrainian Treasures
                    </span>

                      <div class="case-cart__status">
                        <div class="etsy-letter-img"></div>
                      </div>

                      <div class="case-cart__title-sub">
                        www.ukitrsures.com
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="case-cart__body">
                <div class="case-cart__row custom-row">
                  <div class="case-cart__col custom-col custom-col--50">
                    <div class="case-cart__label">
                      Shop ID
                    </div>
                    <div class="case-cart__content">
                      4645756
                    </div>
                  </div>
                  <div class="case-cart__col custom-col custom-col--50">
                    <div class="case-cart__label">
                      User ID
                    </div>
                    <div class="case-cart__content">
                      4645756
                    </div>
                  </div>
                  <div class="case-cart__col custom-col custom-col--50">
                    <div class="case-cart__label">
                      Currency
                    </div>
                    <div class="case-cart__content">
                      USD
                    </div>
                  </div>
                  <div class="case-cart__col custom-col custom-col--50">
                    <div class="case-cart__label">
                      Name
                    </div>
                    <div class="case-cart__content">
                      UkiTreasures
                    </div>
                  </div>
                </div>
              </div>

              <div class="case-cart__bottom">
                <div class="case-cart__btn ml-auto">
                  <router-link class="color-brown" :to="$store.getters.GET_PATHS.mainSettingsEtsyTransferShop">
                    <CaseCartButton
                            :value="'View Listings'"
                            :ico="true"
                            :typeIco="'show'"
                    >
                    </CaseCartButton>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>

      <div class="table-bottom-btn">
        <div class="table-bottom-btn__inner">
          <ShowMore
                  v-if="$store.getters.getShopsCommonList.next_page_url !== null && $store.getters.getShops.length > 0"
                  @click.native="$emit('showMore')"
                  :count="$store.getters.getShopsCommonList.total - $store.getters.getShopsForPage * $store.getters.getShopsCommonList.current_page < $store.getters.getShopsForPage ?
                  $store.getters.getShopsCommonList.total - $store.getters.getShopsForPage * $store.getters.getShopsCommonList.current_page:
                  $store.getters.getShopsForPage"
          />
        </div>
      </div>
    </template>

    <template v-if="($store.getters.getLoadingShops === false && $store.getters.getShops.length === 0)">
      <NoResult
              class="mt-3"
              :countFilterParams="countFilterParams"
              :title="$t('common_noDataYet.localization_value.value')"
              :titleWithFilter="$t('common_noResult.localization_value.value')"
              :text="$t('common_noDataYetTxt.localization_value.value')"
              :textWithFilter="$t('common_noResultSeems.localization_value.value')"
              @resetFilter="$emit('resetFilter')"
      />
    </template>

  </div>
</template>

<script>


  import CaseCartButton from "../../../../../../UI/buttons/CaseCartButton/CaseCartButton";
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import TableUserNameRow from "../../../../../../coreComponents/TableComponents/TableUserNameRow/TableUserNameRow";

  export default {
    name: "EtsyTransferTable",
    components: {
      TableUserNameRow,
      NoResult,
      ShowMore,
      CaseCartButton
    },

    props: {
      countFilterParams: Number,
    },

    // watch: {
    //   getEtsyTransferShopItems() {
    //     if(this._.find(this.$store.getters.getEtsyTransferShopItems, 'shop')){
    //       this.hasListings = true
    //     }
    //   },
    // },

    // computed: {
    //   getEtsyTransferShopItems(){
    //     return this.$store.getters.getEtsyTransferShopItems
    //   }
    // },

    data(){
      return{
        // hasListings: false,
      }
    },

    mounted() {

    },

    methods: {

    }
  }
</script>

<style scoped>
  .unidentified-payments-table{
    padding-top: 24px;
  }

  .etsy-letter-img{
    display: block;
    width: 20px;
    height: 20px;
    background: url("../../../../../../../assets/img/common/etsy-letter.png") center center no-repeat;
  }

  .case-cart__title {
    max-width: 90%;
  }
</style>
