<template>
  <EtsyListingsTableUser
      :countFilterParams="countFilterParams"
      :filterGetParams="filterGetParams"
      @resetFilter="resetFilter"
      @changeFilter="changeFilter"
      @showMore="showMore"
      @reload="reload"
  />
</template>

<script>
  import EtsyListingsTableUser from "./EtsyListingsTableUser/EtsyListingsTableUser";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";
  import {DEFAULT_SHOP_TYPES, SHOPS_ADMIN_TYPES} from "../../../../../staticData/staticVariables";

  export default {
    name: "EtsyListingsTable",
    components: {
      EtsyListingsTableUser,
    },

    mixins: [routeFilter],

    data() {
      return {
        forPage: this.$store.getters.getShopsForPage,
        page: 1,
        onePage: 1,
        filterUserId: '',
        filterId: '',
        filterShopId: '',
        filterShopName: '',

        countFilterParams: 0,
        filterGetParams: {},
      }
    },

    mounted() {
      this.filter()
    },

    methods: {

      getFilterParam() {
        this.filterGetParams = {
          userId: this.$route.query.userId,
          id: this.$route.query.id,
          shopId: this.$route.query.shopId,
          shopName: this.$route.query.shopName,
        }
      },

      filter(next = false) {
        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
            generateMainFilter.page,
            generateMainFilter.forPage
        )

        this.$store.commit('setShopsFilter', window.location.search)

        if (next) this.$store.commit('setNextShopPage', true)
        this.$store.dispatch('fetchShops', url).then(() => {
          this.$store.commit('setNextShopPage', false)
        })

        this.checkCountFilter()
      },


      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: true})

        this.generateFilterQueryParams(
            myQuery,
            {
              filterUserId: 'user',
              filterId: 'id',
              filterShopId: 'shopId',
              filterShopName: 'shopName'
            },
        )

        /**
         * Shop type === Etsy (1)
         */
        myQuery.where('type', DEFAULT_SHOP_TYPES.etsy.id)
        myQuery.where('shopStatus', SHOPS_ADMIN_TYPES.valid)
        myQuery.where('byOauthKeysExist', '1')

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },

    }
  }
</script>

<style scoped>

</style>
