<template>
  <EtsyTransferTableUser
          :countFilterParams="countFilterParams"
          :filterGetParams="filterGetParams"
          @resetFilter="resetFilter"
          @changeFilter="changeFilter"
          @showMore="showMore"
          @reload="reload"
  />
</template>

<script>
  import EtsyTransferTableUser from "./EtsyTransferTableUser/EtsyTransferTableUser";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";
  import {DEFAULT_SHOP_TYPES} from "../../../../../staticData/staticVariables";

  export default {
    name: "EtsyTransferTable",
    components: {
      EtsyTransferTableUser,
    },

    mixins: [routeFilter],

    data() {
      return {
        forPage: this.$store.getters.getShopsForPage,
        page: 1,
        onePage: 1,
        filterId: '',
        filterName: '',
        filterUserName: '',
        filterUserId: '',

        countFilterParams: 0,
        filterGetParams: {},

        listingData: {},

        // navTabs: {
        //   all: {
        //     label: this.$t('etsyTransfer_AllListings.localization_value.value'),
        //     name: 'all',
        //     id: 1,
        //     active: true
        //   },
        // },
      }
    },

    mounted() {

      if(this.$route.query.type !== undefined){
        this.navTabs.all.active = false
        this.navTabs[this.$route.query.type].active = true
      }

      this.filter()
    },

    methods: {

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          id: this.$route.query.id,
          name: this.$route.query.name,
          userName: this.$route.query.userName,
          userId: this.$route.query.userId,
        }
      },

      filter(next = false) {
        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
          generateMainFilter.page,
          generateMainFilter.forPage
        )

        this.$store.commit('setShopsFilter', window.location.search)

        if (next) this.$store.commit('setNextShopPage', true)
        this.$store.dispatch('fetchShops', url).then(() => {
          this.$store.commit('setNextShopPage', false)



          // this.$store.getters.getShops.map((item, i) => {
          //   let index = i
          //   this.$store.dispatch('getShopListingsInfo', {shop_id: item.id}).then(response => {
          //
          //     if(this._.has(response.data.data, 'shop')){
          //       this.$store.commit('addEtsyTransferShopItems', {index: index, data: response.data.data})
          //     } else {
          //       this.$store.commit('addEtsyTransferShopItems', {index: index, data: null})
          //     }
          //   })
          // })
        })


        this.checkCountFilter(['userName'])
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: true})

        myQuery.where('type', DEFAULT_SHOP_TYPES.etsy.id)
        myQuery.where('shopStatus', 'valid')
        myQuery.where('byOauthKeysExist', '1')

        this.generateFilterQueryParams(
          myQuery,
          {
            filterId: 'shopId',
            filterName: 'shopName',
            filterUserId: 'user',
          },
        )

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },

    }


  }
</script>

<style scoped>

</style>
